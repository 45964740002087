import { jsx as _jsx } from "react/jsx-runtime";
import * as Noodl from '@noodl/noodl-sdk';
import { useRef, useEffect, useState } from 'react';
import { Shapes, Rectangle } from '@noodl/geometry';
import { useTrackBounds } from '@noodl/noodl-canvas/src/hooks/useTrackBounds';
export function Minimap({ context }) {
    const rootRef = useRef(null);
    const bounds = useTrackBounds(rootRef);
    const [normalizedNodes, setNormalizedNodes] = useState([]);
    useEffect(() => {
        if (context) {
            function updateNodes() {
                const nodes = context.nodes.list();
                const nodesBounds = context.nodes.getBoundingBox();
                if (context && bounds && nodesBounds) {
                    const innerAspectRatio = Math.max(nodesBounds.width, nodesBounds.height);
                    const outerAspectRatio = Math.max(bounds.width, bounds.height);
                    setNormalizedNodes(nodes.map((x) => new Rectangle(((x.bounds.x - nodesBounds.x) / innerAspectRatio) * outerAspectRatio, ((x.bounds.y - nodesBounds.y) / innerAspectRatio) * outerAspectRatio, (x.bounds.width / innerAspectRatio) * outerAspectRatio, (x.bounds.height / innerAspectRatio) * outerAspectRatio)));
                }
            }
            context.events.addEventListener('nodes', updateNodes);
            return function () {
                context.events.removeEventListener('nodes', updateNodes);
            };
        }
    }, [context, bounds]);
    return (_jsx("div", Object.assign({ style: {
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }, ref: rootRef }, { children: Boolean(bounds) && (_jsx("svg", Object.assign({ viewBox: `0 0 ${bounds.width} ${bounds.height}`, xmlns: "http://www.w3.org/2000/svg" }, { children: normalizedNodes.map((x, i) => (_jsx("path", { d: Shapes.rectangle({
                    start: x.topLeft,
                    end: x.bottomRight
                }).toSvgPath(), fill: "red" }, i))) }))) })));
}
export const canvasMinimap = Noodl.defineReactNode({
    name: 'Canvas Minimap',
    category: 'canvas.module',
    allowChildren: false,
    getReactComponent() {
        return Minimap;
    },
    inputProps: {
        context: {
            displayName: 'Context',
            group: 'Data',
            type: 'object'
        }
    }
});
