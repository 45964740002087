import { useLayoutEffect, useState } from 'react';
/**
 * Track the Bounding Client Rect on a HTMLElement.
 *
 * @param ref The HTML Element we are tracking.
 * @returns ref.current.getBoundingClientRect() result.
 */
export function useTrackBounds(ref) {
    const [bounds, setBounds] = useState(null);
    useLayoutEffect(() => {
        if (!ref.current)
            return;
        const observer = new ResizeObserver(() => {
            var _a;
            // This causes a re-render so lets use it carefully.
            const newBounds = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            setBounds(newBounds || null);
        });
        observer.observe(ref.current);
        setBounds(ref.current.getBoundingClientRect());
        return function () {
            observer.disconnect();
        };
    }, [ref]);
    return bounds;
}
