import { Vector2 } from "@noodl/geometry";
import { useCallback, useEffect, useRef } from "react";
const NavigationModeMouse = "mouse";
const NavigationModeTouchPad = "touchpad";
class MouseWheelModeDetector {
    constructor() {
        this.firstWheelDeltaYInTimeFrame = null;
        this.endTimeFrame = this.endTimeFrameHandler.bind(this);
        this.mode = NavigationModeMouse;
    }
    dispose() {
        clearTimeout(this.checkTimeFrameEndedID);
    }
    changeMode(e) {
        this.startTimeFrame();
        this.mode = this.getModeByWheelEvent(e);
        return this.mode;
    }
    startTimeFrame() {
        this.startFrameTime = Date.now();
        clearTimeout(this.checkTimeFrameEndedID);
        this.checkTimeFrameEndedID = setTimeout(this.endTimeFrame, 100);
    }
    endTimeFrameHandler() {
        this.startFrameTime = 0;
        this.firstWheelDeltaYInTimeFrame = null;
    }
    getModeByWheelEvent(e) {
        var t = this.mode;
        switch (t) {
            case NavigationModeMouse:
                return this.detectTouchpad(e) ? NavigationModeTouchPad : t;
            case NavigationModeTouchPad:
                return this.detectMouse(e) ? NavigationModeMouse : t;
            default:
                return t;
        }
    }
    detectMouse(e) {
        //panning events from a trackpad always have ctrlKey set to true. Just assume it's a trackpad
        if (e.ctrlKey) {
            return false;
        }
        if (
        // @ts-ignore
        e.wheelDeltaY !== 0 &&
            // @ts-ignore
            e.wheelDeltaY !== null &&
            this.firstWheelDeltaYInTimeFrame === null) {
            // @ts-ignore
            this.firstWheelDeltaYInTimeFrame = Math.abs(e.wheelDeltaY);
            //after some testing it appears that mice always report a deltaY of 120, 240 etc in the very first wheel event.
            //Trackpads are more "smooth" with lower values.
            const mouseDetected = this.firstWheelDeltaYInTimeFrame % 120 === 0;
            return mouseDetected;
        }
        return false;
    }
    detectTouchpad(e) {
        //panning events from a trackpad always have ctrlKey set to true. Just assume it's a trackpad
        if (e.ctrlKey) {
            return true;
        }
        if (e.deltaX === 0 || e.shiftKey) {
            return false;
        }
        return true;
    }
}
/**
 * Handling user interaction without any React render.
 *
 * Inspired by:
 * - https://github.com/noodlapp/noodl/blob/e5627e5432aae2847f5dd3401bf32603e3fe423c/packages/noodl-viewer-react/src/canvas/canvas.js
 *
 * @param rootElementRef
 * @param args
 * @returns
 */
export function usePanning(rootElementRef, args) {
    const scale = useRef(args.scale || 1.0);
    const position = useRef(args.position || Vector2.zero);
    const mouseWheelDetector = useRef(new MouseWheelModeDetector());
    const isPanning = useRef(false);
    const lastMousePos = useRef(Vector2.zero);
    const spaceKeyDown = useRef(false);
    const shiftKeyDown = useRef(false);
    // TODO: Listen for changes to position and scale
    //  useEffect(() => {
    //    let update = false;
    //
    //    if (args.scale != scale.current) {
    //      scale.current = args.scale;
    //      update = true;
    //    }
    //
    //    if (!args.position?.equals(position.current)) {
    //      position.current = args.position;
    //      update = true;
    //    }
    //
    //    if (update) {
    //      args.onTransformChanged &&
    //        args.onTransformChanged(position.current, scale.current);
    //    }
    //  }, [args]);
    // Send initial transform
    useEffect(() => args.onTransformChanged &&
        args.onTransformChanged(position.current, scale.current), []);
    const setCursor = useCallback((cursor) => {
        // document.body.style.cursor = cursor;
        rootElementRef.current.style.cursor = cursor;
    }, [rootElementRef]);
    function setPositionAndScale(newPosition, newScale) {
        if (!args.enable) {
            return;
        }
        position.current = newPosition;
        scale.current = newScale;
        args.onTransformChanged &&
            args.onTransformChanged(newPosition, scale.current);
    }
    function _performPan(dx, dy) {
        let newPosition = new Vector2(position.current.x - dx / scale.current, position.current.y - dy / scale.current);
        // Swap the delta on the 2 axis
        if (shiftKeyDown.current) {
            newPosition = new Vector2(position.current.x - dy / scale.current, position.current.y - dx / scale.current);
        }
        // TODO: Clamp newPosition in bounds
        setPositionAndScale(newPosition, scale.current);
    }
    function onMouseUp(eventObject) {
        if (isPanning.current) {
            isPanning.current = false;
            setCursor("initial");
            eventObject.preventDefault();
        }
    }
    function onMouseDown(eventObject) {
        if (eventObject.button === 1 ||
            eventObject.button === 2 ||
            (spaceKeyDown.current && eventObject.button === 0)) {
            isPanning.current = true;
            lastMousePos.current = new Vector2(eventObject.screenX, eventObject.screenY);
            args.onMouseMoved &&
                args.onMouseMoved(new Vector2(eventObject.pageX, eventObject.pageY));
            setCursor("grabbing");
        }
    }
    function onMouseMove(eventObject) {
        const mousePosition = new Vector2(eventObject.screenX, eventObject.screenY);
        args.onMouseMoved &&
            args.onMouseMoved(new Vector2(eventObject.pageX, eventObject.pageY));
        if (isPanning.current) {
            const dx = eventObject.screenX - lastMousePos.current.x;
            const dy = eventObject.screenY - lastMousePos.current.y;
            lastMousePos.current = mousePosition;
            _performPan(dx, dy);
        }
        // Calculate the mouse world position
        if (args.onMouseMove) {
            const bounds = rootElementRef.current.getBoundingClientRect();
            const worldPosition = new Vector2(eventObject.clientX, eventObject.clientY)
                .add(position.current)
                .multiplyByValue(scale.current)
                .subtract(new Vector2(bounds.x, bounds.y));
            args.onMouseMove(worldPosition);
        }
    }
    // Listen to global key up & down events when the mouse is over
    const globalOnKeyDown = useCallback((eventObject) => {
        if (eventObject.code === "Space") {
            spaceKeyDown.current = true;
            setCursor("grab");
            // TODO: Disable user-select
        }
        if (eventObject.key === "Shift") {
            shiftKeyDown.current = true;
        }
    }, []);
    const globalOnKeyUp = useCallback((eventObject) => {
        if (eventObject.code === "Space") {
            spaceKeyDown.current = false;
            setCursor("initial");
        }
        if (eventObject.key === "Shift") {
            shiftKeyDown.current = false;
        }
    }, []);
    function onMouseEnter(_eventObject) {
        document.addEventListener("keydown", globalOnKeyDown, true);
        document.addEventListener("keyup", globalOnKeyUp, true);
    }
    function onMouseLeave(_eventObject) {
        document.removeEventListener("keydown", globalOnKeyDown, true);
        document.removeEventListener("keyup", globalOnKeyUp, true);
        // Reset the state when losing the mouse
        setCursor("initial");
        isPanning.current = false;
        spaceKeyDown.current = false;
        shiftKeyDown.current = false;
    }
    function onMouseWheel(eventObject) {
        const pointerDeviceType = mouseWheelDetector.current.changeMode(eventObject.nativeEvent);
        if (args.enableZoom &&
            (pointerDeviceType === "mouse" ||
                eventObject.ctrlKey ||
                eventObject.metaKey)) {
            const oldScale = scale.current;
            const zoomFactor = pointerDeviceType === "mouse" ? 1 / 100 : 1 / 10;
            const dY = eventObject.deltaY * zoomFactor;
            let newScale = scale.current * Math.pow(0.95, dY);
            newScale = Math.max(Math.min(2.0, newScale), 0.1);
            // TODO: Clamp in bounds
            const newPos = new Vector2(position.current.x -
                (eventObject.pageX / newScale - eventObject.pageX / oldScale), position.current.y -
                (eventObject.pageY / newScale - eventObject.pageY / oldScale));
            setPositionAndScale(newPos, newScale);
        }
        else {
            _performPan(-eventObject.deltaX, -eventObject.deltaY);
        }
    }
    const setState = useCallback((in_position, in_zoom) => {
        if (!isPanning.current) {
            position.current = in_position;
            scale.current = in_zoom;
        }
    }, [isPanning.current]);
    return {
        setState,
        events: {
            onMouseUpCapture: onMouseUp,
            onMouseDownCapture: onMouseDown,
            onMouseMoveCapture: onMouseMove,
            onMouseEnter,
            onMouseLeave,
            onWheelCapture: onMouseWheel,
        },
    };
}
