import { Vector2 } from "../../math";
import { PathBuilder } from "../path_builder";
export var ShapeLineCap;
(function (ShapeLineCap) {
    ShapeLineCap["None"] = "none";
    ShapeLineCap["Line"] = "line";
    ShapeLineCap["Triangle"] = "triangle";
    ShapeLineCap["ReversedTriangle"] = "reversed-triangle";
    ShapeLineCap["Circle"] = "circle";
    ShapeLineCap["Diamond"] = "diamond";
    ShapeLineCap["Square"] = "square";
})(ShapeLineCap || (ShapeLineCap = {}));
function line_arrow(builder, start, end, length = 10) {
    const angle = -(start.subtract(end).normalize().toAngle() + Math.PI / 2);
    const p1 = new Vector2(-length, length).rotate(angle).add(end);
    const p2 = new Vector2(-length, -length).rotate(angle).add(end);
    builder.lineTo(end);
    builder.lineTo(p1);
    builder.lineTo(end);
    builder.lineTo(p2);
    return builder;
}
export function line_cap({ builder = PathBuilder.create(), cap, start, end, }) {
    switch (cap) {
        case ShapeLineCap.Line:
            line_arrow(builder, start, end, 8);
            break;
    }
}
