export { ShapeLineCap } from "./line_cap";
export { ShapeLineType } from "./line";
import { line, appendLine, splineSegment } from "./line";
import { line_cap } from "./line_cap";
import { rectangle } from "./rectangle";
export const Shapes = {
    line,
    splineSegment,
    appendLine,
    line_cap,
    rectangle,
};
