import { useCallback, useEffect, useRef, useState } from 'react';
const EaseCurves = {
    easeOutCubic: function (start, end, t) {
        t--;
        return (end - start) * (t * t * t + 1.0) + start;
    }
};
/**
 * Noodl Timer hook, uses the internal timer in Noodl Apps.
 *
 * Returns a value in the callback based on the Curve type.
 *
 * @param timerScheduler
 * @param callback
 * @param started
 * @param ended
 * @returns
 */
export function useTimer(timerScheduler, callback, started, ended) {
    const timer = useRef(null);
    const [time, setTime] = useState(0);
    const setup = useRef(null);
    const stop = useCallback(() => {
        if (timer.current) {
            timerScheduler.stopTimer(timer.current);
            timer.current = null;
            ended && ended();
        }
    }, [ended, timerScheduler]);
    useEffect(() => {
        if (setup.current) {
            const { startValue, endValue } = setup.current;
            const timeValue = time;
            // TODO: Right now the curve is hardcoded in here
            const value = EaseCurves.easeOutCubic(startValue, endValue, timeValue);
            // Are we going in a positive direction?
            if (startValue >= endValue) {
                callback(Math.max(value, endValue));
                // Stop, we have passed the value
                if (value <= endValue) {
                    stop();
                }
            }
            else {
                callback(Math.min(value, endValue));
                // Stop, we have passed the value
                if (value >= endValue) {
                    stop();
                }
            }
        }
    }, [time, callback, stop]);
    return {
        stop,
        reset: function (duration, startValue, endValue) {
            setup.current = {
                startValue,
                endValue
            };
            timer.current && timer.current.stop();
            timer.current = timerScheduler
                .createTimer({
                duration: duration === undefined ? 300 : duration,
                onStart() {
                    started && started();
                },
                onRunning(t) {
                    setTime(t);
                },
                onFinished() {
                    ended && ended();
                }
            })
                .start();
        }
    };
}
