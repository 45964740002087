export var ModifierType;
(function (ModifierType) {
    ModifierType["Added"] = "added";
    ModifierType["Changed"] = "changed";
    ModifierType["Removed"] = "removed";
})(ModifierType || (ModifierType = {}));
export class CameraEvent extends Event {
    constructor(userAction) {
        super('camera');
        this.userAction = userAction;
    }
}
export var ObjectState;
(function (ObjectState) {
    ObjectState[ObjectState["MouseEnter"] = 0] = "MouseEnter";
    ObjectState[ObjectState["MouseLeave"] = 1] = "MouseLeave";
    ObjectState[ObjectState["MouseMove"] = 2] = "MouseMove";
})(ObjectState || (ObjectState = {}));
export class ObjectStateEvent extends Event {
    constructor(shapeId, state, mousePosition) {
        super(ObjectStateEvent.ID);
        this.shapeId = shapeId;
        this.state = state;
        this.mousePosition = mousePosition;
    }
}
ObjectStateEvent.ID = 'object-state';
export class NodeEvent extends Event {
    constructor(nodeId, modifier) {
        super('nodes');
        this.nodeId = nodeId;
        this.modifier = modifier;
    }
}
export class CanvasEvents extends EventTarget {
    dispatchCameraChanged(userAction) {
        this.dispatchEvent(new CameraEvent(userAction));
    }
    dispatchObjectsChanged() {
        this.dispatchEvent(new Event('objects'));
    }
    dispatchObjectState(id, state, mousePosition) {
        this.dispatchEvent(new ObjectStateEvent(id, state, mousePosition));
    }
    dispatchNodesChanged(id, modifier) {
        this.dispatchEvent(new NodeEvent(id, modifier));
    }
}
