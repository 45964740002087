import { Vector2, Shapes, PathBuilder, BazierCurve2D } from '@noodl/geometry';
import { line_cap } from '@noodl/geometry/src/geometry/shapes/line_cap';
export function intersectObjectElement(elem, position) {
    if (elem.object.kind === 'spline-segment' && elem.bounds.contains(position)) {
        const spline = new BazierCurve2D(Vector2.from(elem.object.start), Vector2.from(elem.object.startControl), Vector2.from(elem.object.endControl), Vector2.from(elem.object.end));
        const closest = spline.closestPoint(position);
        const distance = closest.subtract(position).length();
        return distance < 100;
    }
    return elem.bounds.contains(position);
}
export function canvasObjectToCanvasObjectElement(object) {
    switch (object.kind) {
        case 'line': {
            const pathBuilder = Shapes.line({
                start: Vector2.from(object.start),
                end: Vector2.from(object.end),
                end_cap: object.endCap,
                line_curve: object.lineCurve,
                line_type: object.lineType
            });
            const bounds = pathBuilder.currentBoundingBox();
            const svgPath = pathBuilder.toSvgPath().replace('\n', '');
            return {
                order: object.order || 0,
                object,
                bounds,
                paths: [
                    {
                        d: svgPath,
                        stroke: object.strokeColor,
                        'stroke-width': object.strokeWidth,
                        fill: 'none',
                        'stroke-linejoin': 'round',
                        'stroke-linecap': 'round',
                        'stroke-dasharray': object.strokeDashArray,
                        'stroke-dashoffset': object.strokeDashOffset
                    }
                ]
            };
        }
        case 'spline-segment': {
            const pathBuilder = Shapes.splineSegment(object);
            const bounds = pathBuilder.currentBoundingBox();
            const svgPath = pathBuilder.toSvgPath().replace('\n', '');
            const startCapbuilder = PathBuilder.create();
            startCapbuilder.moveTo(Vector2.from(object.start));
            line_cap({
                builder: startCapbuilder,
                cap: object.startCap,
                start: Vector2.from(object.startControl),
                end: Vector2.from(object.start)
            });
            const endCapbuilder = PathBuilder.create();
            endCapbuilder.moveTo(Vector2.from(object.end));
            line_cap({
                builder: endCapbuilder,
                cap: object.endCap,
                start: Vector2.from(object.endControl),
                end: Vector2.from(object.end)
            });
            const style = {
                stroke: object.strokeColor,
                'stroke-width': object.strokeWidth,
                fill: 'none',
                'stroke-linejoin': 'round',
                'stroke-linecap': 'round'
            };
            return {
                order: object.order || 0,
                object,
                bounds,
                paths: [
                    Object.assign(Object.assign({ d: svgPath }, style), { 'stroke-dasharray': object.strokeDashArray, 'stroke-dashoffset': object.strokeDashOffset }),
                    Object.assign(Object.assign({ d: startCapbuilder.toSvgPath() }, style), { 'stroke-dasharray': [1], 'stroke-dashoffset': 0 }),
                    Object.assign(Object.assign({ d: endCapbuilder.toSvgPath() }, style), { 'stroke-dasharray': [1], 'stroke-dashoffset': 0 })
                ]
            };
        }
        case 'rect': {
            const pathBuilder = Shapes.rectangle({
                start: Vector2.from(object.min),
                end: Vector2.from(object.max),
                borderRadius: object.borderRadius
            });
            const bounds = pathBuilder.currentBoundingBox();
            const svgPath = pathBuilder.toSvgPath().replace('\n', '');
            return {
                order: object.order || 0,
                object,
                bounds,
                paths: [
                    {
                        d: svgPath,
                        stroke: object.strokeColor,
                        fill: object.fillColor
                    }
                ]
            };
        }
    }
}
