import { Rectangle, Vector2 } from "../math";
export class PathBuilder {
    constructor() {
        this.actions = [];
        this.lastMove = new Vector2(0, 0);
        this.position = new Vector2(0, 0);
        this.points = [];
    }
    static create() {
        return new PathBuilder();
    }
    currentBoundingBox() {
        return Rectangle.fromPoints(this.points);
    }
    currentPosition() {
        return this.position;
    }
    toSvgPath() {
        return this.actions.join(" ");
    }
    moveTo(to) {
        this.points.push(to);
        this.actions.push(`M ${to.toString()}`);
        this.position = this.position.add(to);
        this.lastMove = this.position.clone();
        return this;
    }
    lineTo(to) {
        this.points.push(to);
        this.actions.push(`L ${to.toString()}`);
        this.position = this.position.add(to);
        return this;
    }
    close() {
        this.actions.push(`Z`);
        this.position = this.lastMove;
        return this;
    }
    quadraticBezierTo(ctrl, to) {
        this.points.push(to);
        this.actions.push(`Q ${ctrl.toString()}, ${to.toString()}`);
        this.position = this.position.add(to);
        return this;
    }
    cubicBezierTo(start, startCtrl, end, endCtrl) {
        this.points.push(start, end, startCtrl, endCtrl);
        this.actions.push(`M ${start.toString()}`);
        this.actions.push(`C ${startCtrl.toString()}, ${endCtrl.toString()}, ${end.toString()}`);
        this.position = this.position.add(end);
        return this;
    }
    arc(center, radii, sweepAngle, xRotation) {
        throw new Error("Method not implemented.");
    }
}
