import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState, useCallback } from 'react';
import { Vector2, CssTransformVar } from '@noodl/geometry';
import { useTrackBounds } from '../../hooks/useTrackBounds';
import { usePanning } from '../../hooks/usePanning';
import { BackgroundPatternLayer } from './layers/BackgroundPatternLayer';
import { CanvasObjectLayer } from './layers/CanvasObjectLayer';
import { BackgroundPatternVariant, defaultCheckerboardPattern } from '../BackgroundPattern';
export function Canvas({ context, children, cameraEnable, cameraEnableZoom, cameraScale = 1.0, debugShowNodesBoundingBox, backgroundVariant = BackgroundPatternVariant.Dots, backgroundDotsLowerDotSize = 0.2, backgroundDotsUpperDotSize = 2.0, backgroundRectanglethicknessX = 1.7, backgroundRectanglethicknessY = 1.7, backgroundSpacing, backgroundColor, backgroundForegroundColor, backgroundOpacity, onCameraMoved, onNodesChanged, onObjectsChanged, mouseWorldPositionX, mouseWorldPositionY }) {
    // Create a unique hash for our CSS Variables
    const [cssVar] = useState(CssTransformVar.create());
    // Track the Bounding Client Rect of wrapperRef.
    const wrapperRef = useRef(null);
    const bounds = useTrackBounds(wrapperRef);
    useEffect(() => {
        if (bounds) {
            context._viewportBounds = bounds;
            context.camera._updateScreenSize(new Vector2(bounds.width, bounds.height), new Vector2(bounds.x, bounds.y));
        }
    }, [bounds]);
    // Create panning events, with callbacks to avoid React render.
    const onMouseMove = useCallback((worldPosition) => {
        mouseWorldPositionX && mouseWorldPositionX(worldPosition.x);
        mouseWorldPositionY && mouseWorldPositionY(worldPosition.y);
    }, []);
    const onTransformChanged = useCallback((position, scale) => {
        context.camera._update(position, scale);
    }, [wrapperRef.current]);
    const onMouseMoved = useCallback((mousePosition) => {
        context.camera._updateMouse(mousePosition);
    }, [wrapperRef.current]);
    // @ts-ignore
    const panning = usePanning(wrapperRef, {
        enable: cameraEnable,
        position: context.camera.position,
        scale: cameraScale,
        enableZoom: cameraEnableZoom,
        onMouseMove,
        onTransformChanged,
        onMouseMoved
    });
    const childrenRef = useRef(null);
    // const { nodes } = useCanvasTracker(childrenRef, {});
    // useEffect(() => {
    //   context.nodes.update(nodes);
    //   onNodesChanged && onNodesChanged();
    // }, [nodes]);
    useEffect(() => {
        function onCamera(ev) {
            const { position, zoom } = context.camera;
            if (ev.userAction) {
                panning.setState(position, zoom);
            }
            onCameraMoved && onCameraMoved();
            // @ts-ignore
            cssVar.setPosition(wrapperRef.current, position.multiplyByValue(-1.0));
            // @ts-ignore
            cssVar.setScale(wrapperRef.current, zoom);
        }
        function onNodes() {
            onNodesChanged && onNodesChanged();
        }
        function onObjects() {
            onObjectsChanged && onObjectsChanged();
        }
        // @ts-ignore
        context.events.addEventListener('camera', onCamera);
        context.events.addEventListener('nodes', onNodes);
        context.events.addEventListener('objects', onObjects);
        return function () {
            // @ts-ignore
            context.events.removeEventListener('camera', onCamera);
            context.events.removeEventListener('nodes', onNodes);
            context.events.removeEventListener('objects', onObjects);
        };
    }, [context]);
    // Debug: Show Nodes Bounding Box
    useEffect(() => {
        if (!debugShowNodesBoundingBox)
            return;
        function createOrUpdate() {
            const boundingBox = context.nodes.getBoundingBox();
            context.objects.createOrUpdate({
                kind: 'rect',
                id: 'debug-nodes-bounding-box',
                min: boundingBox.topLeft,
                max: boundingBox.bottomRight,
                borderRadius: 0,
                strokeColor: 'red',
                fillColor: 'none'
            });
            const center = boundingBox.center;
            context.objects.createOrUpdate({
                kind: 'rect',
                id: 'debug-nodes-bounding-box-center',
                min: center.subtractByValue(10),
                max: center.addByValue(20),
                borderRadius: 0,
                strokeColor: 'blue',
                fillColor: 'none'
            });
        }
        context.events.addEventListener('nodes', createOrUpdate);
        createOrUpdate();
        return function () {
            context.objects.delete('debug-nodes-bounding-box');
            context.objects.delete('debug-nodes-bounding-box-center');
            context.events.removeEventListener('nodes', createOrUpdate);
        };
    }, [debugShowNodesBoundingBox]);
    function getPattern() {
        switch (backgroundVariant) {
            default:
            case BackgroundPatternVariant.Dots:
                return {
                    kind: BackgroundPatternVariant.Dots,
                    lowerDotSize: backgroundDotsLowerDotSize,
                    upperDotSize: backgroundDotsUpperDotSize
                };
            case BackgroundPatternVariant.Rectangles:
                return {
                    kind: BackgroundPatternVariant.Rectangles,
                    thicknessX: backgroundRectanglethicknessX,
                    thicknessY: backgroundRectanglethicknessY
                };
            case BackgroundPatternVariant.Checkerboard:
                return defaultCheckerboardPattern;
        }
    }
    // React JSX
    return (_jsxs("div", Object.assign({ style: {
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        }, ref: wrapperRef }, panning.events, { onContextMenuCapture: (e) => {
            // TODO: When not on node
            e.preventDefault();
        } }, { children: [Boolean(backgroundVariant !== BackgroundPatternVariant.Transparent) && (_jsx(BackgroundPatternLayer, { cssVar: cssVar, backgroundPattern: getPattern(), backgroundSpacing: backgroundSpacing, backgroundColor: backgroundColor, backgroundForegroundColor: backgroundForegroundColor, backgroundOpacity: backgroundOpacity })), context && Boolean(bounds === null || bounds === void 0 ? void 0 : bounds.width) && _jsx(CanvasObjectLayer, { context: context, cssVar: cssVar }), _jsx("div", Object.assign({ style: {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                } }, { children: _jsx("div", Object.assign({ style: {
                        transform: cssVar.toCssTransform()
                    }, ref: childrenRef }, { children: children })) }))] })));
}
