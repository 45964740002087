export class Vector2 {
    constructor(x, y) {
        this.x = x;
        this.y = y;
        if (typeof x !== "number" || typeof y !== "number") {
            throw new Error("x or y is not a number");
        }
    }
    add(other) {
        return new Vector2(this.x + other.x, this.y + other.y);
    }
    addByValue(value) {
        return new Vector2(this.x + value, this.y + value);
    }
    subtract(other) {
        return new Vector2(this.x - other.x, this.y - other.y);
    }
    subtractByValue(value) {
        return new Vector2(this.x - value, this.y - value);
    }
    divide(other) {
        return new Vector2((this.x + Number.EPSILON) / other.x, (this.y + Number.EPSILON) / other.y);
    }
    divideByValue(value) {
        return new Vector2((this.x + Number.EPSILON) / value, (this.y + Number.EPSILON) / value);
    }
    multiply(other) {
        return new Vector2(this.x * other.x, this.y * other.y);
    }
    multiplyByValue(value) {
        return new Vector2(this.x * value, this.y * value);
    }
    equals(other) {
        return this.x == other.x && this.y == other.y;
    }
    /**
     * Returns the squared length of this Vector2.
     * @returns The squared length of this Vector2.
     */
    length() {
        return this.x * this.x + this.y * this.y;
    }
    /**
     * Creates a new Vector2 that contains a normalized values from another vector.
     * @returns Unit vector.
     */
    normalize() {
        const val = 1.0 / (Math.sqrt(this.length()) + Number.EPSILON);
        return new Vector2(this.x * val, this.y * val);
    }
    /**
     * Returns a new Vector2 rounded to the nearest integers.
     * @returns The new vector.
     */
    round() {
        return new Vector2(Math.round(this.x), Math.round(this.y));
    }
    toAngle() {
        return Math.atan2(this.x, this.y);
    }
    rotate(angle) {
        const s = Math.sin(angle);
        const c = Math.cos(angle);
        return new Vector2(this.x * c - this.y * s, this.x * s + this.y * c);
    }
    clone() {
        return new Vector2(this.x, this.y);
    }
    toString() {
        return `${this.x} ${this.y}`;
    }
    /**
     * Returns a dot product of two vectors.
     * @param value1 The first vector.
     * @param value2 The second vector.
     * @returns The dot product of two vectors.
     */
    static dot(value1, value2) {
        return value1.x * value2.x + value1.y * value2.y;
    }
    /**
     * Returns the distance between two vectors.
     * @param value1 The first vector.
     * @param value2 The second vector.
     * @returns The distance between two vectors.
     */
    static distance(value1, value2) {
        return value2.subtract(value1).length();
    }
    static from(vector) {
        if (vector instanceof Vector2) {
            return vector;
        }
        return new Vector2(vector.x, vector.y);
    }
    static fromArray(array) {
        // TODO: Improve this ugly code
        if (array.length !== 2) {
            throw new Error();
        }
        return new Vector2(Number(array[0].toString().trim()), Number(array[1].toString().trim()));
    }
}
Vector2.zero = new Vector2(0, 0);
