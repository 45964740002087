import { ShapeLineCap, line_cap } from "./line_cap";
import { Vector2 } from "../../math";
import { PathBuilder } from "../path_builder";
export var ShapeLineType;
(function (ShapeLineType) {
    ShapeLineType["Default"] = "default";
    ShapeLineType["ManhattanHorizontal"] = "manhattan-horizontal";
    ShapeLineType["ManhattanVertical"] = "manhattan-vertical";
})(ShapeLineType || (ShapeLineType = {}));
export function line(options) {
    const builder = PathBuilder.create();
    appendLine(builder, options);
    return builder;
}
export function appendLine(builder, { start, end, end_cap = ShapeLineCap.None, line_curve, line_type = ShapeLineType.Default, merge = false, }) {
    let connection = new Vector2(start.x, start.y);
    switch (line_type) {
        case ShapeLineType.ManhattanHorizontal:
            connection = new Vector2(start.x, end.y);
            break;
        case ShapeLineType.ManhattanVertical:
            connection = new Vector2(end.x, start.y);
            break;
    }
    if (merge) {
        builder.lineTo(start);
    }
    else {
        builder.moveTo(start);
    }
    if (line_curve && (connection.y !== start.y || connection.x !== start.x)) {
        const curve_length = Math.min(line_curve, connection.subtract(start).length(), connection.subtract(end).length());
        const curve_point_xd = end.x - connection.x > 0 ? curve_length : -curve_length;
        const curve_point_yd = end.y - connection.y > 0 ? curve_length : -curve_length;
        let curve_point_y1 = connection.y + curve_point_yd * (end.y > start.y ? 1 : -1);
        let curve_point_x1 = connection.x;
        let curve_point_x2 = connection.x + curve_point_xd;
        let curve_point_y2 = connection.y;
        if (line_type === ShapeLineType.ManhattanVertical) {
            curve_point_y1 = connection.y;
            curve_point_x1 =
                connection.x + curve_point_xd * (start.x > end.x ? -1 : 1);
            curve_point_x2 = connection.x;
            curve_point_y2 =
                connection.y + curve_point_yd * (start.y > connection.y ? -1 : 1);
        }
        builder.lineTo(new Vector2(curve_point_x1, curve_point_y1));
        builder.quadraticBezierTo(connection, new Vector2(curve_point_x2, curve_point_y2));
        builder.lineTo(end);
        line_cap({
            builder,
            cap: end_cap,
            start: connection,
            end,
        });
        return builder;
    }
    builder.lineTo(end);
    line_cap({
        builder,
        cap: end_cap,
        start: connection,
        end,
    });
    return builder;
}
export function splineSegment({ start, startControl, end, endControl, }) {
    const builder = PathBuilder.create();
    const vStart = Vector2.from(start);
    const vStartCtrl = Vector2.from(startControl);
    const vEnd = Vector2.from(end);
    const vEndCtrl = Vector2.from(endControl);
    builder.moveTo(vStart);
    builder.cubicBezierTo(vStart, vStartCtrl, vEnd, vEndCtrl);
    return builder;
}
