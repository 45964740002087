export class CanvasContextSettings {
    constructor(settings) {
        this.settings = settings;
    }
    static create(settings = {}) {
        return new CanvasContextSettings(Object.assign(Object.assign({}, CanvasContextSettings.default), settings));
    }
}
CanvasContextSettings.default = {
    object_track_events: false,
};
