import { jsx as _jsx } from "react/jsx-runtime";
import { CssTransformVar } from '@noodl/geometry';
import { BackgroundPatternVariant, defaultDotsPattern } from './common';
export function BackgroundPattern({ elementRef, cssVar = CssTransformVar.create(), pattern = defaultDotsPattern, spacing = 20, backgroundColor = '#FFFFFF', foregroundColor = '#F2F2F2', opacity = 0.8 }) {
    const limitedSpacing = Math.max(4, Math.min(40, spacing));
    const cssVarPositionX = cssVar.positionX;
    const cssVarPositionY = cssVar.positionY;
    const cssVarScale = cssVar.scale;
    function getVariantPositionStyle() {
        switch (pattern.kind) {
            default:
                return {
                    backgroundPositionX: `var(${cssVarPositionX} * var(${cssVarScale}, 1)`,
                    backgroundPositionY: `var(${cssVarPositionY} * var(${cssVarScale}, 1)`
                };
            case BackgroundPatternVariant.Dots: {
                const halfSpacing = limitedSpacing / 2;
                return {
                    backgroundPositionX: `calc((var(${cssVarPositionX}, 0px) + ${halfSpacing}px) * var(${cssVarScale}, 1))`,
                    backgroundPositionY: `calc((var(${cssVarPositionY}, 0px) + ${halfSpacing}px) * var(${cssVarScale}, 1))`
                };
            }
            case BackgroundPatternVariant.Checkerboard: {
                return {
                    backgroundPositionX: `calc(var(${cssVarPositionX}, 0px) * var(${cssVarScale}, 1)), calc((var(${cssVarPositionX}, 0px) + ${limitedSpacing}px) * var(${cssVarScale}, 1))`,
                    backgroundPositionY: `calc(var(${cssVarPositionY}, 0px) * var(${cssVarScale}, 1)), calc((var(${cssVarPositionY}, 0px) + ${limitedSpacing}px) * var(${cssVarScale}, 1))`
                };
            }
        }
    }
    function getVariantStyle() {
        switch (pattern.kind) {
            default:
            case BackgroundPatternVariant.Dots: {
                const cssSpacing = `${limitedSpacing}px`;
                return {
                    backgroundImage: `radial-gradient(${foregroundColor} ${pattern.lowerDotSize}px, ${backgroundColor} ${pattern.upperDotSize}px)`,
                    backgroundSize: `calc(${cssSpacing} * var(${cssVarScale}, 1)) calc(${cssSpacing} * var(${cssVarScale}, 1))`
                };
            }
            case BackgroundPatternVariant.Rectangles: {
                const cssSpacing = `${limitedSpacing}px`;
                return {
                    backgroundImage: `linear-gradient(${foregroundColor} ${pattern.thicknessX}px, transparent ${pattern.thicknessX}px),
             linear-gradient(to right, ${foregroundColor} ${pattern.thicknessY}px, ${backgroundColor} ${pattern.thicknessY}px)`,
                    backgroundSize: `calc(${cssSpacing} * var(${cssVarScale}, 1)) calc(${cssSpacing} * var(${cssVarScale}, 1))`
                };
            }
            case BackgroundPatternVariant.Checkerboard: {
                const cssSpacing = `${limitedSpacing * 2}px`;
                return {
                    backgroundImage: `repeating-linear-gradient(45deg, ${foregroundColor} 25%, transparent 25%, transparent 75%, ${foregroundColor} 75%, ${foregroundColor}),
          repeating-linear-gradient(45deg, ${foregroundColor} 25%, ${backgroundColor} 25%, ${backgroundColor} 75%, ${foregroundColor} 75%, ${foregroundColor})`,
                    backgroundSize: `calc(${cssSpacing} * var(${cssVarScale}, 1)) calc(${cssSpacing} * var(${cssVarScale}, 1))`
                };
            }
        }
    }
    const style = Object.assign(Object.assign(Object.assign({}, getVariantStyle()), getVariantPositionStyle()), { backgroundColor,
        opacity, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 });
    return _jsx("div", { ref: elementRef, style: style });
}
