import { jsx as _jsx } from "react/jsx-runtime";
import { CanvasNode } from '@noodl/noodl-canvas/src/components/CanvasNode/CanvasNode';
export const canvasNode = {
    name: 'Canvas Node',
    docs: 'https://docs.noodl.net/library/modules/canvas/canvas-node',
    category: 'canvas.module',
    allowChildren: true,
    noodlNodeAsProp: true,
    connectionPanel: {
        groupPriority: [
            'General',
            'Snap To Position X',
            'Snap To Position Y',
            'Snap To Grid',
            'Events',
            'Actions',
            'States'
        ]
    },
    getReactComponent() {
        return function (props) {
            function getContext() {
                var _a;
                const parent = props.noodlNode.getVisualParentNode();
                return (_a = parent === null || parent === void 0 ? void 0 : parent.model) === null || _a === void 0 ? void 0 : _a.context;
            }
            return _jsx(CanvasNode, Object.assign({ context: getContext(), timeSchedular: props.noodlNode.context.timerScheduler }, props));
        };
    },
    initialize() {
        this._internal.snapPositionX = 0;
        this._internal.snapPositionY = 0;
        this._internal.snapDurationX = 300;
        this._internal.snapDurationY = 300;
    },
    methods: {
        setNodeModel(nodeModel) {
            var _a;
            // @ts-ignore
            this.__proto__.__proto__.setNodeModel.call(this, nodeModel);
            if (typeof nodeModel.parent !== 'undefined' && ((_a = nodeModel.parent) === null || _a === void 0 ? void 0 : _a.type) !== 'Canvas Viewport') {
                throw new Error('Parent Node have to be a Canvas Viewport');
            }
        }
    },
    setup() { },
    dynamicports: [
        {
            condition: 'snapToGrid_enabled = true',
            inputs: ['snapToGrid_gridSizeX', 'snapToGrid_gridSizeY', 'snapToGrid_duration']
        }
    ],
    inputs: {
        'snapToPositionX.do': {
            group: 'Snap To Position X',
            displayName: 'Do',
            editorName: 'Do|Snap To Position X',
            type: 'signal',
            valueChangedToTrue() {
                this.scheduleAfterInputsHaveUpdated(() => {
                    const { snapPositionX, snapDurationX } = this._internal;
                    this.innerReactComponentRef && this.innerReactComponentRef.snapToPositionX(snapPositionX, snapDurationX);
                });
            }
        },
        'snapToPositionX.value': {
            default: 0,
            group: 'Snap To Position X',
            displayName: 'Value',
            editorName: 'Value|Snap To Position X',
            type: 'number',
            set(value) {
                this._internal.snapPositionX = value;
            }
        },
        'snapToPositionX.duration': {
            default: 300,
            group: 'Snap To Position X',
            displayName: 'Duration',
            editorName: 'Duration|Snap To Position X',
            type: 'number',
            set(value) {
                this._internal.snapDurationX = value;
            }
        },
        'snapToPositionY.do': {
            group: 'Snap To Position Y',
            displayName: 'Do',
            editorName: 'Do|Snap To Position Y',
            type: 'signal',
            valueChangedToTrue() {
                this.scheduleAfterInputsHaveUpdated(() => {
                    const { snapPositionY, snapDurationY } = this._internal;
                    this.innerReactComponentRef && this.innerReactComponentRef.snapToPositionY(snapPositionY, snapDurationY);
                });
            }
        },
        'snapToPositionY.value': {
            default: 0,
            group: 'Snap To Position Y',
            displayName: 'Value',
            editorName: 'Value|Snap To Position Y',
            type: 'number',
            set(value) {
                this._internal.snapPositionY = value;
            }
        },
        'snapToPositionY.duration': {
            default: 300,
            group: 'Snap To Position Y',
            displayName: 'Duration',
            editorName: 'Duration|Snap To Position Y',
            type: 'number',
            set(value) {
                this._internal.snapDurationY = value;
            }
        },
        'snapToGrid.do': {
            group: 'Snap To Grid',
            displayName: 'Do',
            editorName: 'Do|Snap To Grid',
            type: 'signal',
            valueChangedToTrue() {
                this.scheduleAfterInputsHaveUpdated(() => {
                    this.innerReactComponentRef && this.innerReactComponentRef.snapToGrid();
                });
            }
        }
    },
    inputProps: {
        id: {
            group: 'General',
            displayName: 'Id',
            type: 'string'
        },
        enabled: {
            group: 'General',
            displayName: 'Enabled',
            type: 'boolean',
            default: true
        },
        inputPositionX: {
            displayName: 'Start Position X',
            type: {
                name: 'number'
            },
            default: 0
        },
        inputPositionY: {
            displayName: 'Start Position Y',
            type: {
                name: 'number'
            },
            default: 0
        },
        defaultzIndex: {
            group: 'Default Style',
            displayName: 'zIndex',
            type: 'number'
        },
        draggingzIndex: {
            group: 'Dragging Style',
            displayName: 'zIndex',
            type: 'number',
            default: 100
        },
        snapToGrid_enabled: {
            group: 'Snap To Grid',
            displayName: 'Enabled',
            type: 'boolean',
            default: true
        },
        snapToGrid_gridSizeX: {
            group: 'Snap To Grid',
            displayName: 'Grid Size X',
            type: 'number',
            default: 20
        },
        snapToGrid_gridSizeY: {
            group: 'Snap To Grid',
            displayName: 'Grid Size Y',
            type: 'number',
            default: 20
        },
        snapToGrid_duration: {
            default: 300,
            group: 'Snap To Grid',
            displayName: 'Duration',
            editorName: 'Duration|Snap To Grid',
            type: 'number'
        }
    },
    outputProps: {
        snapToPositionY_started: {
            group: 'Snap',
            type: 'signal',
            displayName: 'Snap To Position Y Started'
        },
        snapToPositionY_ended: {
            group: 'Snap',
            type: 'signal',
            displayName: 'Snap To Position Y Ended'
        },
        snapToPositionX_started: {
            group: 'Snap',
            type: 'signal',
            displayName: 'Snap To Position X Started'
        },
        snapToPositionX_ended: {
            group: 'Snap',
            type: 'signal',
            displayName: 'Snap To Position X Ended'
        },
        onStart: {
            group: 'Signals',
            type: 'signal',
            displayName: 'Drag Started'
        },
        onStop: {
            group: 'Signals',
            type: 'signal',
            displayName: 'Drag Ended'
        },
        onDrag: {
            group: 'Signals',
            type: 'signal',
            displayName: 'Drag Moved'
        },
        positionX: {
            group: 'Values',
            displayName: 'Position X',
            type: 'number'
        },
        positionY: {
            group: 'Values',
            displayName: 'Position Y',
            type: 'number'
        },
        deltaX: {
            group: 'Values',
            displayName: 'Delta X',
            type: 'number'
        },
        deltaY: {
            group: 'Values',
            displayName: 'Delta Y',
            type: 'number'
        }
    }
};
