import { Vector2 } from '../math';
import { createGuid } from '../utils/hash';
/**
 * circumvent react and set the transform directly to avoid jittery performance
 */
export class CssTransformVar {
    constructor(hash) {
        this._position = Vector2.zero;
        this._scale = 1.0;
        if (!hash) {
            throw new Error("'hash' is undefined.");
        }
        this.positionX = `--canvas-${hash}-position-x`;
        this.positionY = `--canvas-${hash}-position-y`;
        this.scale = `--canvas-${hash}-scale`;
    }
    static create() {
        return new CssTransformVar(createGuid());
    }
    setPosition(element, value) {
        element.style.setProperty(this.positionX, value.x + 'px');
        element.style.setProperty(this.positionY, value.y + 'px');
    }
    setScale(element, value) {
        element.style.setProperty(this.scale, value.toString());
    }
    toCssTransform() {
        return `scale(var(${this.scale}, 1)) translate(var(${this.positionX}, 0px), var(${this.positionY}, 0px))`;
    }
}
export class CssTransform {
    constructor() {
        this._position = Vector2.zero;
        this._scale = 1.0;
    }
    static create() {
        return new CssTransform();
    }
    get position() {
        return this._position;
    }
    get scale() {
        return this._scale;
    }
    setElement(element) {
        this._element = element;
        this.updateElement();
    }
    setPositionX(value) {
        this._position = new Vector2(value, this.position.y);
        this.updateElement();
    }
    setPositionY(value) {
        this._position = new Vector2(this.position.x, value);
        this.updateElement();
    }
    setPosition(value) {
        this._position = value;
        this.updateElement();
    }
    setScale(value) {
        this._scale = value;
        this.updateElement();
    }
    updateElement() {
        if (!this._element)
            return;
        this._element.style.setProperty('transform', `scale(${this.scale}) translate(${this.position.x}px, ${this.position.y}px)`);
    }
}
