import { jsx as _jsx } from "react/jsx-runtime";
import { BackgroundPattern } from '../../BackgroundPattern';
export function BackgroundPatternLayer({ cssVar, backgroundPattern, backgroundSpacing, backgroundColor, backgroundForegroundColor, backgroundOpacity = 0.8 }) {
    return (_jsx("div", Object.assign({ style: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        } }, { children: _jsx(BackgroundPattern, { cssVar: cssVar, pattern: backgroundPattern, spacing: backgroundSpacing, backgroundColor: backgroundColor, foregroundColor: backgroundForegroundColor, opacity: backgroundOpacity }) })));
}
