/** Inspired by: https://www.magicpattern.design/tools/css-backgrounds */
export var BackgroundPatternVariant;
(function (BackgroundPatternVariant) {
    BackgroundPatternVariant["Transparent"] = "transparent";
    BackgroundPatternVariant["Dots"] = "dots";
    BackgroundPatternVariant["Rectangles"] = "rectangles";
    BackgroundPatternVariant["Checkerboard"] = "checkerboard";
})(BackgroundPatternVariant || (BackgroundPatternVariant = {}));
export const defaultDotsPattern = {
    kind: BackgroundPatternVariant.Dots,
    lowerDotSize: 0.5,
    upperDotSize: 2.0,
};
export const defaultRectanglePattern = {
    kind: BackgroundPatternVariant.Rectangles,
    thicknessX: 1.7,
    thicknessY: 1.7,
};
export const defaultCheckerboardPattern = {
    kind: BackgroundPatternVariant.Checkerboard,
};
export function CanvasBackgroundVariantEnumList() {
    return [
        {
            value: BackgroundPatternVariant.Dots.toString(),
            label: "Dots",
        },
        {
            value: BackgroundPatternVariant.Rectangles.toString(),
            label: "Rectangles",
        },
        {
            value: BackgroundPatternVariant.Checkerboard.toString(),
            label: "Checkerboard",
        },
    ];
}
export function CanvasBackgroundVariantEnumListWithTransparent() {
    return [
        {
            value: BackgroundPatternVariant.Transparent.toString(),
            label: "Transparent",
        },
        ...CanvasBackgroundVariantEnumList(),
    ];
}
