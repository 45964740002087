import { Vector2 } from '@noodl/geometry';
import { ObjectState } from '../context';
import { intersectObjectElement } from '../geometry';
export class CanvasObjectRenderer {
    constructor(context, targetFps = 30) {
        this.context = context;
        this._width = 100;
        this._height = 100;
        this._ratio = 1.0;
        // target fps
        this._interval = 1000 / 30;
        this._lastTime = new Date().getTime();
        this._currentTime = 0;
        this._delta = 0;
        this._objectMouseIds = [];
        this._element = document.createElement('canvas');
        const ctx = this._element.getContext('2d');
        if (!ctx)
            throw new Error('Unable to create canvas context');
        this._ctx = ctx;
        this._interval = 1000 / targetFps;
        this._onMouseMove = this.onMouseMove.bind(this);
        this.loop();
    }
    dispose() { }
    setParentElement(element) {
        var _a, _b, _c;
        const lastTopElem = (_c = (_b = (_a = this._elementParent) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.lastElementChild;
        if (lastTopElem) {
            // @ts-ignore
            lastTopElem.removeEventListener('mousemove', this._onMouseMove);
        }
        this._elementParent = element;
        this._elementParent.style.top = '0';
        this._elementParent.style.bottom = '0';
        this._elementParent.style.left = '0';
        this._elementParent.style.right = '0';
        // @ts-ignore
        const topElem = this._elementParent.parentElement.parentElement.lastElementChild;
        // @ts-ignore
        topElem.addEventListener('mousemove', this._onMouseMove);
        if (this._element.parentElement) {
            this._element.parentElement.removeChild(this._element);
        }
        this._elementParent.appendChild(this._element);
        this.onResize();
        this._observer = new ResizeObserver(this.onResize.bind(this));
        this._observer.observe(this._elementParent);
    }
    onMouseMove(ev) {
        const cameraPosition = this.context.camera.position;
        const cameraZoom = this.context.camera.zoom;
        const position = new Vector2(ev.offsetX, ev.offsetY)
            .multiplyByValue(cameraZoom)
            .add(cameraPosition);
        const objects = this.context.objects.list();
        const hits = objects.filter((x) => intersectObjectElement(x, position));
        const oldIds = [...this._objectMouseIds];
        const newIds = hits.map((x) => x.object.id);
        this._objectMouseIds
            .filter((x) => !newIds.includes(x))
            .forEach((x) => {
            this.context.events.dispatchObjectState(x, ObjectState.MouseLeave, position);
        });
        this._objectMouseIds.length = 0;
        if (hits.length > 0) {
            hits.forEach((x) => {
                const id = x.object.id;
                if (!id)
                    return;
                this._objectMouseIds.push(id);
                if (oldIds.includes(id)) {
                    this.context.events.dispatchObjectState(id, ObjectState.MouseMove, position);
                }
                else {
                    this.context.events.dispatchObjectState(id, ObjectState.MouseEnter, position);
                }
            });
        }
    }
    getDevicePixelRatio() {
        const dpr = window.devicePixelRatio || 1;
        const bsr = 
        // @ts-ignore deprecated
        this._ctx.webkitBackingStorePixelRatio ||
            // @ts-ignore deprecated
            this._ctx.mozBackingStorePixelRatio ||
            // @ts-ignore deprecated
            this._ctx.msBackingStorePixelRatio ||
            // @ts-ignore deprecated
            this._ctx.oBackingStorePixelRatio ||
            // @ts-ignore deprecated
            this._ctx.backingStorePixelRatio ||
            1;
        const r = dpr / bsr;
        return r;
    }
    onResize() {
        this._ratio = this.getDevicePixelRatio();
        const bounds = this._elementParent.getBoundingClientRect();
        this._element.style.width = bounds.width + 'px';
        this._element.style.height = bounds.height + 'px';
        this._element.width = this._width = bounds.width * this._ratio;
        this._element.height = this._height = bounds.height * this._ratio;
    }
    loop() {
        requestAnimationFrame(this.loop.bind(this));
        this._currentTime = new Date().getTime();
        this._delta = this._currentTime - this._lastTime;
        this.update();
        if (this._delta > this._interval) {
            this.render();
            this._lastTime = this._currentTime - (this._delta % this._interval);
        }
    }
    update() {
        const cameraPosition = this.context.camera.position;
        const cameraZoom = this.context.camera.zoom;
        const cameraTransform = new DOMMatrix()
            .scale(cameraZoom * this._ratio, cameraZoom * this._ratio)
            .translate(-cameraPosition.x, -cameraPosition.y);
        this._ctx.setTransform(cameraTransform);
    }
    render() {
        const objects = this.context.objects.list().sort((a, b) => a.order - b.order);
        // scissor clear... we want full clear
        // this._ctx.clearRect(0, 0, this._width, this._height);
        // Clear all
        this._ctx.save();
        this._ctx.setTransform(1, 0, 0, 1, 0, 0);
        this._ctx.clearRect(0, 0, this._width, this._height);
        this._ctx.restore();
        objects.forEach((object) => {
            object.paths.forEach((path) => {
                var p = new Path2D(path.d);
                this._ctx.beginPath();
                this._ctx.lineJoin = path['stroke-linejoin'];
                this._ctx.lineCap = path['stroke-linecap'];
                // @ts-ignore
                this._ctx.strokeStyle = path['stroke'];
                // @ts-ignore
                this._ctx.lineWidth = path['stroke-width'];
                if (path['stroke-dasharray']) {
                    this._ctx.setLineDash(path['stroke-dasharray']);
                }
                // @ts-ignore
                this._ctx.lineDashOffset = path['stroke-dashoffset'];
                this._ctx.stroke(p);
            });
            // if (object.object.kind === "spline-segment") {
            //   const spline = new BazierCurve2D(
            //     Vector2.from(object.object.start),
            //     Vector2.from(object.object.startControl),
            //     Vector2.from(object.object.endControl),
            //     Vector2.from(object.object.end)
            //   );
            //
            //   for (let index = 0; index < 10; index++) {
            //     const t = index / 10;
            //     const v = spline.atPoint(t);
            //     this._ctx.beginPath();
            //     this._ctx.arc(v.x, v.y, 8, 0, 2 * Math.PI);
            //     this._ctx.stroke();
            //   }
            //
            //   this._ctx.strokeStyle = "red";
            //   this._ctx.beginPath();
            //   this._ctx.rect(
            //     object.bounds.x,
            //     object.bounds.y,
            //     object.bounds.width,
            //     object.bounds.height
            //   );
            //   this._ctx.stroke();
            // }
        });
    }
}
