/**
 * Returns a random hash with 4 characters.
 *
 * @returns A random 4 hash.
 */
export function createHash() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
/**
 * Returns a random GUID.
 *
 * Example:
 * - '317d2779-da78-c783-9673-2a774984d0f6'
 *
 * @returns A random GUID.
 */
export function createGuid() {
    const s4 = createHash;
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
