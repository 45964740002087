import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { CssTransform, Rectangle, Vector2 } from '@noodl/geometry';
import { useTimer } from '../../hooks/useTimer';
import { useDragNode } from '../../hooks/useDragNode';
import { useTrackBounds } from '../../hooks/useTrackBounds';
export function CanvasNode({ context, timeSchedular, id, enabled = true, inputPositionX = 0, inputPositionY = 0, defaultzIndex = undefined, draggingzIndex = 100, snapToGrid_enabled = true, snapToGrid_gridSizeX = 20, snapToGrid_gridSizeY = 20, snapToGrid_duration = 300, className, children, style, snapToPositionY_started, snapToPositionY_ended, snapToPositionX_started, snapToPositionX_ended, onStart, onStop, onDrag, positionX, positionY, deltaX, deltaY }) {
    const currentId = useRef(id);
    const rootRef = useRef(null);
    const cssTransform = useRef(CssTransform.create());
    // TODO: Remove isDragging2, but change isDragging to useRef
    const [isDragging, setIsDragging] = useState(false);
    const isDragging2 = useRef(false);
    const bounds = useTrackBounds(rootRef);
    // Method that is called every time the node is moved.
    const onUpdateTransform = useCallback(() => {
        // Find the Canvas Context
        if (currentId.current && context && rootRef.current && rootRef.current.parentElement) {
            // Get both this and the parents bounding boxes,
            // the parent in this case should always be the canvas viewport
            const parentBoundingBox = context._viewportBounds;
            // TODO: Figure out why the bounds changes on dragging
            const boundingBox = isDragging2.current
                ? rootRef.current.getBoundingClientRect()
                : bounds || rootRef.current.getBoundingClientRect();
            let rect = Rectangle.fromDOMRect(boundingBox).offset(
            // Remove the camera position from the transform.
            new Vector2(-parentBoundingBox.x, -parentBoundingBox.y));
            rect = new Rectangle(cssTransform.current.position.x, cssTransform.current.position.y, rect.width, rect.height);
            // Give the canvas context the new position of this node
            context.nodes.updateSingleTransform(currentId.current, rect);
        }
    }, [id, bounds, rootRef.current]);
    useEffect(() => {
        if (inputPositionX) {
            cssTransform.current.setPositionX(inputPositionX);
            positionX && positionX(inputPositionX);
        }
        if (inputPositionY) {
            cssTransform.current.setPositionY(inputPositionY);
            positionY && positionY(inputPositionY);
        }
        onUpdateTransform();
    }, []);
    //
    // snap
    //
    const snapToPositionXTimerCallback = useCallback((value) => {
        cssTransform.current.setPositionX(value);
        positionX && positionX(value);
        onUpdateTransform();
    }, [onUpdateTransform]);
    const snapToPositionYTimerCallback = useCallback((value) => {
        cssTransform.current.setPositionY(value);
        positionY && positionY(value);
        onUpdateTransform();
    }, [onUpdateTransform]);
    const snapToPositionXTimer = useTimer(timeSchedular, snapToPositionXTimerCallback, snapToPositionX_started, snapToPositionX_ended);
    const snapToPositionYTimer = useTimer(timeSchedular, snapToPositionYTimerCallback, snapToPositionY_started, snapToPositionY_ended);
    //
    // methods
    //
    const snapToPositionX = useCallback((x, duration, from) => {
        if (cssTransform.current.position.x === x)
            return;
        if (isDragging)
            return;
        snapToPositionXTimer.reset(duration, from || cssTransform.current.position.x, x);
    }, [context, positionX, isDragging]);
    const snapToPositionY = useCallback((y, duration, from) => {
        if (cssTransform.current.position.y === y)
            return;
        if (isDragging)
            return;
        snapToPositionYTimer.reset(duration, from || cssTransform.current.position.y, y);
    }, [context, positionX, cssTransform.current, isDragging]);
    const snapToGrid = useCallback((fromX, fromY) => {
        const _fromX = fromX || cssTransform.current.position.x;
        const _fromY = fromY || cssTransform.current.position.y;
        const x = Math.floor((_fromX + snapToGrid_gridSizeX / 2) / snapToGrid_gridSizeX) *
            snapToGrid_gridSizeX;
        const y = Math.floor((_fromY + snapToGrid_gridSizeY / 2) / snapToGrid_gridSizeY) *
            snapToGrid_gridSizeY;
        snapToPositionX(x, snapToGrid_duration, _fromX);
        snapToPositionY(y, snapToGrid_duration, _fromY);
    }, [snapToGrid_gridSizeX, snapToGrid_gridSizeY, snapToPositionX, snapToPositionY]);
    //
    // drag
    //
    const onDragStartCb = useCallback((data) => {
        snapToPositionXTimer.stop();
        snapToPositionYTimer.stop();
        setIsDragging(true);
        isDragging2.current = true;
        positionX && positionX(data.x);
        positionY && positionY(data.y);
        deltaX && deltaX(data.deltaX);
        deltaY && deltaY(data.deltaY);
        onStart && onStart();
    }, [positionX, positionY, deltaX, deltaY, onStart]);
    const onDragCb = useCallback((data) => {
        positionX && positionX(data.x);
        positionY && positionY(data.y);
        deltaX && deltaX(data.deltaX);
        deltaY && deltaY(data.deltaY);
        onUpdateTransform();
        onDrag && onDrag();
    }, [positionX, positionY, deltaX, deltaY, onDrag, onUpdateTransform]);
    const onDragEndCb = useCallback((data) => {
        setIsDragging(false);
        isDragging2.current = false;
        positionX && positionX(data.x);
        positionY && positionY(data.y);
        onUpdateTransform();
        if (snapToGrid_enabled) {
            snapToGrid(data.x, data.y);
        }
        onStop && onStop();
    }, [positionX, positionY, onUpdateTransform]);
    const { startMouseDrag, startTouchDrag } = useDragNode({
        transform: cssTransform.current,
        onDragStart: onDragStartCb,
        onDrag: onDragCb,
        onDragEnd: onDragEndCb
    });
    useEffect(() => {
        if (id) {
            if (currentId.current !== id) {
                context.nodes.delete(id);
            }
            // Register the node in the context
            currentId.current = id;
            context.nodes.create(id, {
                node: null,
                methods: {
                    snapToPositionX,
                    snapToPositionY,
                    snapToGrid
                }
            });
            return function () {
                context.nodes.delete(id);
            };
        }
    }, [id]);
    useEffect(() => {
        if (currentId.current) {
            if (context) {
                context.nodes.updateMethods(currentId.current, {
                    snapToPositionX,
                    snapToPositionY,
                    snapToGrid
                });
            }
        }
    }, [rootRef.current, snapToPositionX, snapToPositionY, snapToGrid]);
    useEffect(() => {
        if (!rootRef.current)
            return;
        cssTransform.current.setElement(rootRef.current);
        onUpdateTransform();
    }, [rootRef.current, onUpdateTransform]);
    return (_jsx("div", Object.assign({ ref: rootRef, className: className, style: Object.assign(Object.assign({}, (style || {})), { position: 'absolute', 
            // TODO(performance): Set directly in DOM
            zIndex: isDragging ? draggingzIndex : defaultzIndex }), onMouseDown: (e) => {
            if (e.button === 0) {
                e.preventDefault();
                if (enabled) {
                    startMouseDrag(e.nativeEvent);
                }
            }
        }, onTouchStart: (e) => {
            e.preventDefault();
            if (enabled) {
                startTouchDrag(e.nativeEvent);
            }
        } }, { children: children })));
}
