import { createGuid, Rectangle } from '@noodl/geometry';
import { canvasObjectToCanvasObjectElement } from '../geometry';
export var ObjectEventType;
(function (ObjectEventType) {
    ObjectEventType[ObjectEventType["Modified"] = 0] = "Modified";
    ObjectEventType[ObjectEventType["Deleted"] = 1] = "Deleted";
})(ObjectEventType || (ObjectEventType = {}));
class ObjectTracker {
    constructor() {
        this._events = {};
    }
    get events() {
        return Object.values(this._events);
    }
    clear() {
        this._events = {};
    }
    pullEvents() {
        const events = this.events;
        this.clear();
        return events;
    }
    onChange(id, state) {
        this._events[id] = { id, state };
    }
}
export class CanvasContextObjects {
    constructor(context) {
        this.context = context;
        this._objects = {};
        this._boundingBox = Rectangle.zero;
        this.changes = new ObjectTracker();
    }
    get(id) {
        return this._objects[id] || null;
    }
    list() {
        // @ts-ignore
        return Object.values(this._objects).filter((x) => x !== null);
    }
    getBoundingBox() {
        return this._boundingBox;
    }
    createOrUpdate(object) {
        const id = object.id || createGuid();
        this._objects[id] = canvasObjectToCanvasObjectElement(object);
        if (this.context.settings.object_track_events) {
            this.changes.onChange(id, ObjectEventType.Modified);
        }
        this.onChanged();
        return id;
    }
    delete(id) {
        delete this._objects[id];
        if (this.context.settings.object_track_events) {
            this.changes.onChange(id, ObjectEventType.Deleted);
        }
        this.onChanged();
    }
    onChanged() {
        // Calculate objects bounding box
        const boundingBox = Rectangle.merge(this.list().map((object) => object.bounds));
        if (boundingBox) {
            this._boundingBox = boundingBox;
        }
        else {
            this._boundingBox = Rectangle.zero;
        }
        // Notify that the objects have been changed
        this.context.events.dispatchObjectsChanged();
    }
}
