import * as Noodl from "@noodl/noodl-sdk";
import { canvasMinimap } from "./nodes/CanvasMinimap";
import { canvasLine } from "./nodes/CanvasLine";
import { canvasRect } from "./nodes/CanvasRect";
import { canvasViewportNode } from "./nodes/CanvasViewport";
import { canvasNode } from "./nodes/CanvasNode";
import { canvasNodeConnection } from "./nodes/CanvasNodeConnection";
import { canvasBackground } from "./nodes/CanvasBackground";
Noodl.defineModule({
    reactNodes: [
        canvasViewportNode,
        canvasNode,
        canvasNodeConnection,
        canvasMinimap,
        canvasLine,
        canvasRect,
        canvasBackground,
    ],
    nodes: [],
    settings: [],
    setup() { },
});
