import { Vector2 } from '@noodl/geometry';
import { useEffect, useRef } from 'react';
export const canvasLine = {
    name: 'Canvas Line',
    category: 'canvas.module',
    allowChildren: false,
    noodlNodeAsProp: true,
    getReactComponent() {
        return function (props) {
            const objectIdRef = useRef(undefined);
            function getContext() {
                var _a;
                const parent = props.noodlNode.getVisualParentNode();
                return (_a = parent === null || parent === void 0 ? void 0 : parent.model) === null || _a === void 0 ? void 0 : _a.context;
            }
            function createObject(_context) {
                return {
                    kind: 'line',
                    id: objectIdRef.current,
                    start: new Vector2(props.startX, props.startY),
                    startCap: props.startCap,
                    end: new Vector2(props.endX, props.endY),
                    endCap: props.endCap,
                    strokeColor: props.strokeColor,
                    strokeWidth: props.strokeWidth,
                    lineType: props.lineType,
                    lineCurve: props.lineCurve,
                    strokeDashArray: props.strokeDashArray || [],
                    strokeDashOffset: props.strokeDashOffset || 1.0
                };
            }
            // Create and delete
            const deleteContext = useRef(null);
            useEffect(() => {
                const context = getContext();
                if (context) {
                    deleteContext.current = context;
                    objectIdRef.current = context.objects.createOrUpdate(createObject(context));
                    return function () {
                        deleteContext.current.objects.delete(objectIdRef.current);
                    };
                }
            }, [props.id]);
            // Redraw
            const context = getContext();
            if (context && objectIdRef.current) {
                context.objects.createOrUpdate(createObject(context));
            }
            return null;
        };
    },
    inputProps: {
        startX: {
            displayName: 'Start X',
            group: 'Line',
            default: 0,
            type: 'number'
        },
        startY: {
            displayName: 'Start Y',
            group: 'Line',
            default: 0,
            type: 'number'
        },
        endX: {
            displayName: 'End X',
            group: 'Line',
            default: 100,
            type: 'number'
        },
        endY: {
            displayName: 'End Y',
            group: 'Line',
            default: 100,
            type: 'number'
        },
        strokeColor: {
            displayName: 'Stroke Color',
            group: 'Style',
            default: 'blue',
            type: 'color'
        },
        strokeWidth: {
            displayName: 'Stroke Width',
            group: 'Style',
            default: 2,
            type: 'number'
        },
        lineType: {
            displayName: 'Line Type',
            group: 'Line Style',
            default: 'manhattan-horizontal',
            type: {
                name: 'enum',
                enums: [
                    {
                        label: 'Default',
                        value: 'default'
                    },
                    {
                        label: 'Manhattan Horizontal',
                        value: 'manhattan-horizontal'
                    },
                    {
                        label: 'Manhattan Vertical',
                        value: 'manhattan-vertical'
                    }
                ]
            }
        },
        lineCurve: {
            displayName: 'Line Curve',
            group: 'Line',
            default: 10,
            type: 'number'
        },
        endCap: {
            displayName: 'End Cap',
            group: 'Line Style',
            default: 'line',
            type: {
                name: 'enum',
                enums: [
                    {
                        label: 'None',
                        value: 'none'
                    },
                    {
                        label: 'Line',
                        value: 'line'
                    }
                ]
            }
        },
        startCap: {
            displayName: 'Start Cap',
            group: 'Line Style',
            default: 'none',
            type: {
                name: 'enum',
                enums: [
                    {
                        label: 'None',
                        value: 'none'
                    },
                    {
                        label: 'Line',
                        value: 'line'
                    }
                ]
            }
        },
        strokeDashArray: {
            displayName: 'Stroke Dash Array',
            group: 'Style',
            default: [1],
            type: 'array'
        },
        strokeDashOffset: {
            displayName: 'Stroke Dash Offset',
            group: 'Style',
            default: 0,
            type: 'number'
        }
    }
};
