import { LineSegment2D } from "./line_segment_2d";
import { Vector2 } from "./vector2";
export var RectanglePoint;
(function (RectanglePoint) {
    RectanglePoint[RectanglePoint["TopLeft"] = 0] = "TopLeft";
    RectanglePoint[RectanglePoint["TopCenter"] = 1] = "TopCenter";
    RectanglePoint[RectanglePoint["TopRight"] = 2] = "TopRight";
    RectanglePoint[RectanglePoint["MiddleLeft"] = 3] = "MiddleLeft";
    RectanglePoint[RectanglePoint["MiddleCenter"] = 4] = "MiddleCenter";
    RectanglePoint[RectanglePoint["MiddleRight"] = 5] = "MiddleRight";
    RectanglePoint[RectanglePoint["BottomLeft"] = 6] = "BottomLeft";
    RectanglePoint[RectanglePoint["BottomCenter"] = 7] = "BottomCenter";
    RectanglePoint[RectanglePoint["BottomRight"] = 8] = "BottomRight";
})(RectanglePoint || (RectanglePoint = {}));
export var RectangleDirection;
(function (RectangleDirection) {
    RectangleDirection[RectangleDirection["Left"] = 0] = "Left";
    RectangleDirection[RectangleDirection["Top"] = 1] = "Top";
    RectangleDirection[RectangleDirection["Right"] = 2] = "Right";
    RectangleDirection[RectangleDirection["Bottom"] = 3] = "Bottom";
})(RectangleDirection || (RectangleDirection = {}));
export function getRectanglePointNormal(value) {
    switch (value) {
        case RectanglePoint.TopLeft:
            return new Vector2(-1, -1);
        case RectanglePoint.TopCenter:
            return new Vector2(0, -1);
        case RectanglePoint.TopRight:
            return new Vector2(-1, -1);
        case RectanglePoint.MiddleLeft:
            return new Vector2(-1, 0);
        case RectanglePoint.MiddleCenter:
            return new Vector2(0, 0);
        case RectanglePoint.MiddleRight:
            return new Vector2(1, 0);
        case RectanglePoint.BottomLeft:
            return new Vector2(-1, 1);
        case RectanglePoint.BottomCenter:
            return new Vector2(0, 1);
        case RectanglePoint.BottomRight:
            return new Vector2(1, 1);
    }
}
export class Rectangle {
    constructor(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }
    /**
     * Returns a new Rectangle with the same values.
     *
     * @param value The value for X, Y, width and height.
     * @returns A new Rectangle.
     */
    static same(value) {
        return new Rectangle(value, value, value, value);
    }
    /**
     * Returns a new Rectnalge with the same X / width and Y / height.
     *
     * @param x The value for X & width.
     * @param y The value for Y & height.
     * @returns A new Rectangle.
     */
    static symmetric(x, y) {
        return new Rectangle(x, y, x, y);
    }
    get topLeft() {
        return new Vector2(this.left, this.top);
    }
    get bottomRight() {
        return new Vector2(this.right, this.bottom);
    }
    get absoluteWidth() {
        return Math.abs(this.width - this.x);
    }
    get absoluteHeight() {
        return Math.abs(this.height - this.y);
    }
    get center() {
        const halfWidth = this.width / 2.0;
        const halfHeight = this.height / 2.0;
        return new Vector2(this.x + halfWidth, this.y + halfHeight);
    }
    get size() {
        return new Vector2(this.width, this.height);
    }
    get position() {
        return new Vector2(this.x, this.y);
    }
    /**
     * Gets the left side of the rectangle.
     */
    get left() {
        return this.x;
    }
    /**
     * Gets the right side of the rectangle.
     */
    get right() {
        return this.x + this.width;
    }
    /**
     * Gets the top  side of the rectangle.
     */
    get top() {
        return this.y;
    }
    /**
     * Gets the bottom side of the rectangle.
     */
    get bottom() {
        return this.y + this.height;
    }
    /**
     * Extend the Rectangle on all sides.
     *
     * @param other
     * @returns
     */
    extend(other) {
        return new Rectangle(this.x - other.x, this.y - other.y, this.width + other.width + other.x, this.height + other.height + other.y);
    }
    /**
     * Round all the values.
     *
     */
    round() {
        return new Rectangle(Math.round(this.x), Math.round(this.y), Math.round(this.width), Math.round(this.height));
    }
    contains(value) {
        return (this.x <= value.x &&
            value.x < this.x + this.width &&
            this.y <= value.y &&
            value.y < this.y + this.height);
    }
    intersects(value) {
        return (value.left < this.right &&
            this.left < value.right &&
            value.top < this.bottom &&
            this.top < value.bottom);
    }
    offset(value) {
        return new Rectangle(this.x + value.x, this.y + value.y, this.width, this.height);
    }
    toString() {
        return `x: ${this.x}, y: ${this.y}, width: ${this.width}, height: ${this.height}`;
    }
    toViewBoxString() {
        return `${this.x} ${this.y} ${this.width} ${this.height}`;
    }
    getPoint(point) {
        switch (point) {
            case RectanglePoint.TopLeft:
                return new Vector2(this.left, this.top);
            case RectanglePoint.TopCenter:
                return new Vector2(this.left + this.width / 2.0, this.top);
            case RectanglePoint.TopRight:
                return new Vector2(this.right, this.top);
            case RectanglePoint.MiddleLeft:
                return new Vector2(this.left, this.top + this.height / 2.0);
            case RectanglePoint.MiddleCenter:
                return this.center;
            case RectanglePoint.MiddleRight:
                return new Vector2(this.right, this.top + this.height / 2.0);
            case RectanglePoint.BottomLeft:
                return new Vector2(this.bottom, this.left);
            case RectanglePoint.BottomCenter:
                return new Vector2(this.left + this.width / 2.0, this.bottom);
            case RectanglePoint.BottomRight:
                return new Vector2(this.right, this.bottom);
        }
    }
    getLine(direction) {
        switch (direction) {
            case RectangleDirection.Left:
                return new LineSegment2D(this.getPoint(RectanglePoint.BottomLeft), this.getPoint(RectanglePoint.TopLeft));
            case RectangleDirection.Top:
                return new LineSegment2D(this.getPoint(RectanglePoint.TopLeft), this.getPoint(RectanglePoint.TopRight));
            case RectangleDirection.Right:
                return new LineSegment2D(this.getPoint(RectanglePoint.TopRight), this.getPoint(RectanglePoint.BottomRight));
            case RectangleDirection.Bottom:
                return new LineSegment2D(this.getPoint(RectanglePoint.BottomRight), this.getPoint(RectanglePoint.BottomLeft));
        }
    }
    /**
     *
     * @param other
     * @param horizontalAngles
     *  Default: undefined = 0.7853981633974483 (PI_OVER_4)
     *
     *  This is the horizontal angles since we split up a circle into 4 sections.
     *  The sections on the left and right side can be made smaller or bigger with
     *  this input.
     * @returns
     */
    closestIntermediatePointType(other, horizontalAngles) {
        // 0 will be up going in counter clockwise order
        let angle = (other.subtract(this.center).normalize().toAngle() + Math.PI) / 2.0;
        if (horizontalAngles) {
            const halfPI = Math.PI / 2.0;
            // One edge cant extend over it's half.
            if (horizontalAngles < 0 || horizontalAngles > halfPI) {
                throw new Error(`horizontalAngles is outside the limited range. (current: ${horizontalAngles}, min: 0, max: ${halfPI})`);
            }
            const nextHalf = halfPI - horizontalAngles;
            const angles = [
                nextHalf,
                nextHalf + horizontalAngles,
                nextHalf + horizontalAngles + nextHalf,
                nextHalf + horizontalAngles + nextHalf + horizontalAngles,
            ];
            // Since the top is 0, we push the angle by half of the extract angle.
            // One piece at the top and one at the bottom.
            angle += nextHalf / 2;
            let index = angles.findIndex((x) => angle < x);
            // Add a fallback just in case there is something odd
            // this should never happen
            if (index === -1) {
                index = 0;
            }
            return [
                RectanglePoint.TopCenter,
                RectanglePoint.MiddleLeft,
                RectanglePoint.BottomCenter,
                RectanglePoint.MiddleRight,
            ][index];
        }
        else {
            const delta = Math.PI / 4.0;
            const value = Math.abs(Math.round(angle / delta) % 4);
            return [
                RectanglePoint.TopCenter,
                RectanglePoint.MiddleLeft,
                RectanglePoint.BottomCenter,
                RectanglePoint.MiddleRight,
            ][value];
        }
    }
    closestIntermediatePoint(other) {
        const point = this.closestIntermediatePointType(other);
        return this.getPoint(point);
    }
    static fromDOMRect(rect) {
        return new Rectangle(rect.x, rect.y, rect.width, rect.height);
    }
    /**
     *
     * @param points
     * @returns
     */
    static fromPoints(points) {
        if (points.length === 0) {
            return null;
        }
        let top = points[0].y;
        let bottom = points[0].y;
        let left = points[0].x;
        let right = points[0].x;
        points.forEach((point) => {
            if (point.y < top)
                top = point.y;
            if (point.y > bottom)
                bottom = point.y;
            if (point.x < left)
                left = point.x;
            if (point.x > right)
                right = point.x;
        });
        return new Rectangle(left, top, right - left, bottom - top);
    }
    /**
     *
     * @param rectangles
     * @returns
     */
    static merge(rectangles) {
        if (rectangles.length === 0) {
            return null;
        }
        if (rectangles.length === 1) {
            return rectangles[0];
        }
        let top = rectangles[0].top;
        let bottom = rectangles[0].bottom;
        let left = rectangles[0].left;
        let right = rectangles[0].right;
        rectangles.forEach((rect) => {
            if (rect.top < top)
                top = rect.top;
            if (rect.bottom > bottom)
                bottom = rect.bottom;
            if (rect.left < left)
                left = rect.left;
            if (rect.right > right)
                right = rect.right;
        });
        return new Rectangle(left, top, right - left, bottom - top);
    }
}
Rectangle.zero = new Rectangle(0, 0, 0, 0);
