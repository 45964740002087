import { Vector2 } from "./vector2";
function bezierInterpolation(t, a, b, c, d) {
    var t2 = t * t;
    var t3 = t2 * t;
    return (a +
        (-a * 3 + t * (3 * a - a * t)) * t +
        (3 * b + t * (-6 * b + b * 3 * t)) * t +
        (c * 3 - c * 3 * t) * t2 +
        d * t3);
}
export class BazierCurve2D {
    constructor(start, startCtrl, endCtrl, end) {
        this.start = start;
        this.startCtrl = startCtrl;
        this.endCtrl = endCtrl;
        this.end = end;
    }
    get midpoint() {
        return new Vector2(this.start.x * 0.5 + this.endCtrl.x * 0.5, this.start.y * 0.5 + this.endCtrl.y * 0.5);
    }
    get center() {
        return this.start.add(this.end).divideByValue(2);
    }
    atPoint(offset) {
        return new Vector2(bezierInterpolation(offset, this.start.x, this.startCtrl.x, this.endCtrl.x, this.end.x), bezierInterpolation(offset, this.start.y, this.startCtrl.y, this.endCtrl.y, this.end.y));
    }
    closestPoint(position) {
        const offset = this._findClosestPointOnCurve(position, 0, 1);
        return this.atPoint(offset);
    }
    closestPointOffset(position) {
        const offset = this._findClosestPointOnCurve(position, 0, 1);
        return offset;
    }
    _findClosestPointOnCurve(p, t0, t1) {
        var thres = 0.05;
        var _t0 = t0 + (t1 - t0) * 0.25;
        var p0 = this.atPoint(_t0);
        var pd0 = (p0.x - p.x) * (p0.x - p.x) + (p0.y - p.y) * (p0.y - p.y);
        var _t1 = t0 + (t1 - t0) * 0.75;
        var p1 = this.atPoint(_t1);
        var pd1 = (p1.x - p.x) * (p1.x - p.x) + (p1.y - p.y) * (p1.y - p.y);
        if (Math.abs(t0 - t1) < thres)
            return t0 * 0.5 + t1 * 0.5;
        if (pd0 < pd1)
            return this._findClosestPointOnCurve(p, t0, t0 + (t1 - t0) * 0.5);
        else
            return this._findClosestPointOnCurve(p, t0 + (t1 - t0) * 0.5, t1);
    }
}
