import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
export function CanvasObjectLayer({ context, cssVar }) {
    const parentRef = useRef(null);
    useEffect(() => {
        if (!parentRef.current)
            return;
        if (!context._canvasRenderer)
            return;
        context._canvasRenderer.setParentElement(parentRef.current);
    }, [parentRef.current, context]);
    return (_jsx("div", Object.assign({ style: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        } }, { children: _jsx("div", { ref: parentRef, style: {
                position: "absolute",
                // NOTE: Comment out this line for Canvas rendering
                // transform: cssVar.toCssTransform(),
            } }) })));
}
