import { Vector2 } from "@noodl/geometry";
export class CanvasContextCamera {
    constructor(context) {
        this.context = context;
        this._mousePosition = Vector2.zero;
        this._position = Vector2.zero;
        this._zoom = 1.0;
        this._screenSize = Vector2.zero;
        this._screenPosition = Vector2.zero;
    }
    get mousePosition() {
        return this._mousePosition;
    }
    get position() {
        return this._position;
    }
    get zoom() {
        return this._zoom;
    }
    get screenSize() {
        return this._screenSize;
    }
    get screenPosition() {
        return this._screenPosition;
    }
    moveTo(value, duration) {
        // TODO: Duration
        const center = new Vector2(value.x, value.y).subtract(this._screenSize.divideByValue(2));
        this._position = center;
        this.context.events.dispatchCameraChanged(true);
    }
    _update(position, zoom) {
        this._position = position;
        this._zoom = zoom;
        this.context.events.dispatchCameraChanged(false);
    }
    _updateMouse(mousePosition) {
        this._mousePosition = mousePosition
            .add(this._position)
            .subtract(this._screenPosition);
        this.context.events.dispatchCameraChanged(false);
    }
    _updateScreenSize(size, position) {
        this._screenSize = size;
        this._screenPosition = position;
        this.context.events.dispatchCameraChanged(false);
    }
}
