import { appendLine, ShapeLineType } from "./line";
import { Vector2 } from "../../math";
import { PathBuilder } from "../path_builder";
export function rectangle({ start, end, borderRadius, }) {
    const builder = PathBuilder.create();
    if (borderRadius) {
        const delta = end.subtract(start);
        builder.moveTo(start.add(new Vector2(delta.x / 2, 0)));
        appendLine(builder, {
            start: start.add(new Vector2(delta.x / 2, 0)),
            end: start.add(new Vector2(delta.x, delta.y / 2)),
            line_curve: borderRadius,
            line_type: ShapeLineType.ManhattanVertical,
            merge: true,
        });
        appendLine(builder, {
            start: start.add(new Vector2(delta.x, delta.y / 2)),
            end: start.add(new Vector2(delta.x / 2, delta.y)),
            line_curve: borderRadius,
            line_type: ShapeLineType.ManhattanHorizontal,
            merge: true,
        });
        appendLine(builder, {
            start: start.add(new Vector2(delta.x / 2, delta.y)),
            end: start.add(new Vector2(0, delta.y / 2)),
            line_curve: borderRadius,
            line_type: ShapeLineType.ManhattanVertical,
            merge: true,
        });
        appendLine(builder, {
            start: start.add(new Vector2(0, delta.y / 2)),
            end: start.add(new Vector2(delta.x / 2, 0)),
            line_curve: borderRadius,
            line_type: ShapeLineType.ManhattanHorizontal,
            merge: true,
        });
        return builder;
    }
    builder.moveTo(start);
    builder.lineTo(new Vector2(start.x, end.y));
    builder.lineTo(end);
    builder.lineTo(new Vector2(end.x, start.y));
    builder.close();
    return builder;
}
