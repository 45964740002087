import { RectangleDirection } from "./rectangle";
import { Vector2 } from "./vector2";
/**
 * Defines a line segment in 2D space.
 */
export class LineSegment2D {
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }
    /**
     * Returns the normal of this LineSegment2D.
     * @returns The normal.
     */
    normal() {
        return new Vector2(this.end.x - this.start.x, this.end.y - this.start.y).normalize();
    }
    /**
     * Returns the center of this LineSegment2D.
     * @returns The center.
     */
    center() {
        return new Vector2(0.5 * (this.start.x + this.end.x), 0.5 * (this.start.y + this.end.y));
    }
    /**
     * Returns the squared length of this LineSegment2D.
     * @returns
     */
    length() {
        const xx = this.start.x - this.end.x;
        const yy = this.start.y - this.end.y;
        return Math.sqrt(xx * xx + yy * yy);
    }
    /**
     * Find the closest point between start and end.
     * @param point
     * @returns
     */
    closestPointOnLine(point) {
        const lineLength = Vector2.distance(this.start, this.end);
        const lineDir = this.end.subtract(this.start).divideByValue(lineLength);
        const distance = Vector2.dot(point.subtract(this.start), lineDir);
        if (distance <= 0) {
            return this.start;
        }
        if (distance >= lineLength) {
            return this.end;
        }
        return this.start.add(lineDir.multiplyByValue(distance));
    }
    /**
     * Returns the intersection point between two LineSegment2D.
     * @param other
     * @returns
     */
    intersect(other) {
        const x1 = this.end.x - this.start.x;
        const y1 = this.end.y - this.start.y;
        const x2 = other.end.x - other.start.x;
        const y2 = other.end.y - other.start.y;
        const d = x1 * y2 - y1 * x2;
        if (d == 0) {
            return null;
        }
        const x3 = other.start.x - this.start.x;
        const y3 = other.start.y - this.start.y;
        const t = (x3 * y2 - y3 * x2) / d;
        const u = (x3 * y1 - y3 * x1) / d;
        if (t < 0 || t > 1 || u < 0 || u > 1) {
            return null;
        }
        return new Vector2(this.start.x + t * x1, this.start.y + t * y1);
    }
    intersectRectangle(rect) {
        const line1 = rect.getLine(RectangleDirection.Left);
        const line1Result = this.intersect(line1);
        if (line1Result) {
            return line1Result;
        }
        const line2 = rect.getLine(RectangleDirection.Top);
        const line2Result = this.intersect(line2);
        if (line2Result) {
            return line2Result;
        }
        const line3 = rect.getLine(RectangleDirection.Right);
        const line3Result = this.intersect(line3);
        if (line3Result) {
            return line3Result;
        }
        const line4 = rect.getLine(RectangleDirection.Bottom);
        const line4Result = this.intersect(line4);
        if (line4Result) {
            return line4Result;
        }
        return null;
    }
}
