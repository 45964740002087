import { Vector2 } from '@noodl/geometry';
import { useEffect, useRef } from 'react';
export const canvasRect = {
    name: 'Canvas Rectangle',
    category: 'canvas.module',
    allowChildren: false,
    noodlNodeAsProp: true,
    getReactComponent() {
        return function (props) {
            const objectIdRef = useRef(undefined);
            function getContext() {
                var _a;
                const parent = props.noodlNode.getVisualParentNode();
                return (_a = parent === null || parent === void 0 ? void 0 : parent.model) === null || _a === void 0 ? void 0 : _a.context;
            }
            function createObject(_context) {
                return {
                    kind: 'rect',
                    id: objectIdRef.current,
                    min: new Vector2(props.minX, props.minY),
                    max: new Vector2(props.maxX, props.maxY),
                    borderRadius: props.borderRadius,
                    strokeColor: props.strokeColor,
                    fillColor: props.fillColor
                };
            }
            // Create and delete
            const deleteContext = useRef(null);
            useEffect(() => {
                const context = getContext();
                if (context) {
                    deleteContext.current = context;
                    objectIdRef.current = context.objects.createOrUpdate(createObject(context));
                    return function () {
                        deleteContext.current.objects.delete(objectIdRef.current);
                    };
                }
            }, [props.id]);
            // Redraw
            const context = getContext();
            if (context && objectIdRef.current) {
                context.objects.createOrUpdate(createObject(context));
            }
            return null;
        };
    },
    inputProps: {
        minX: {
            displayName: 'Min X',
            group: 'Rectangle',
            default: 0,
            type: 'number',
            set() {
                this.updateOrCreateObject();
            }
        },
        minY: {
            displayName: 'Min Y',
            group: 'Rectangle',
            default: 0,
            type: 'number',
            set() {
                this.updateOrCreateObject();
            }
        },
        maxX: {
            displayName: 'End X',
            group: 'Rectangle',
            default: 100,
            type: 'number',
            set() {
                this.updateOrCreateObject();
            }
        },
        maxY: {
            displayName: 'End Y',
            group: 'Rectangle',
            default: 100,
            type: 'number',
            set() {
                this.updateOrCreateObject();
            }
        },
        borderRadius: {
            displayName: 'Border Radius',
            group: 'Style',
            default: 0,
            type: 'number',
            set() {
                this.updateOrCreateObject();
            }
        },
        strokeColor: {
            displayName: 'Stroke Color',
            group: 'Style',
            default: 'blue',
            type: 'color',
            set() {
                this.updateOrCreateObject();
            }
        },
        fillColor: {
            displayName: 'Fill Color',
            group: 'Style',
            default: 'transparent',
            type: 'color',
            set() {
                this.updateOrCreateObject();
            }
        }
    }
};
