import { Vector2 } from '@noodl/geometry';
import { useCallback, useRef } from 'react';
export function useDragNode({ transform, onDragStart, onDrag, onDragEnd }) {
    const startScreenPos = useRef(Vector2.zero);
    const startNodePos = useRef(Vector2.zero);
    const touchIdentifier = useRef(null);
    const delta = useRef(Vector2.zero);
    const onMouseMove = useCallback((ev) => {
        const startDeltaX = startScreenPos.current.x - ev.x;
        const startDeltaY = startScreenPos.current.y - ev.y;
        const newPosition = startNodePos.current.subtract(new Vector2(startDeltaX, startDeltaY));
        const deltaX = transform.position.x - newPosition.x;
        const deltaY = transform.position.y - newPosition.y;
        transform.setPosition(newPosition);
        onDrag &&
            onDrag({
                x: transform.position.x,
                y: transform.position.y,
                deltaX,
                deltaY
            });
    }, [transform, onDrag]);
    const onMouseUp = useCallback((ev) => {
        onDragEnd &&
            onDragEnd({
                x: transform.position.x,
                y: transform.position.y,
                // TODO: What delta do we want here?
                deltaX: 0,
                deltaY: 0
            });
        document.removeEventListener('mouseup', onMouseUp, true);
        document.removeEventListener('mousemove', onMouseMove, true);
    }, [transform, onDragEnd]);
    const startMouseDrag = useCallback((ev) => {
        startScreenPos.current = new Vector2(ev.x || ev.clientX, ev.y);
        startNodePos.current = (transform === null || transform === void 0 ? void 0 : transform.position) || Vector2.zero;
        delta.current = startNodePos.current;
        onDragStart &&
            onDragStart({
                x: startNodePos.current.x,
                y: startNodePos.current.y,
                deltaX: 0,
                deltaY: 0
            });
        document.addEventListener('mouseup', onMouseUp, true);
        document.addEventListener('mousemove', onMouseMove, true);
    }, [transform, onDragStart]);
    const onTouchMove = useCallback((ev) => {
        const touch = Array.from(ev.touches).find((x) => x.identifier === touchIdentifier.current);
        if (!touch) {
            return;
        }
        const startDeltaX = startScreenPos.current.x - touch.clientX;
        const startDeltaY = startScreenPos.current.y - touch.clientY;
        const newPosition = startNodePos.current.subtract(new Vector2(startDeltaX, startDeltaY));
        const deltaX = transform.position.x - newPosition.x;
        const deltaY = transform.position.y - newPosition.y;
        transform.setPosition(newPosition);
        onDrag &&
            onDrag({
                x: transform.position.x,
                y: transform.position.y,
                deltaX,
                deltaY
            });
    }, [transform, onDrag]);
    const onTouchEnd = useCallback((ev) => {
        const touch = Array.from(ev.touches).find((x) => x.identifier === touchIdentifier.current);
        if (!touch) {
            onDragEnd &&
                onDragEnd({
                    x: transform.position.x,
                    y: transform.position.y,
                    // TODO: What delta do we want here?
                    deltaX: 0,
                    deltaY: 0
                });
            document.removeEventListener('touchend', onTouchEnd, true);
            document.removeEventListener('touchmove', onTouchMove, true);
        }
    }, [transform, onDragEnd]);
    const startTouchDrag = useCallback((ev) => {
        const touch = ev.targetTouches[0];
        touchIdentifier.current = touch.identifier;
        startScreenPos.current = new Vector2(touch.clientX, touch.clientY);
        startNodePos.current = (transform === null || transform === void 0 ? void 0 : transform.position) || Vector2.zero;
        delta.current = startNodePos.current;
        onDragStart &&
            onDragStart({
                x: startNodePos.current.x,
                y: startNodePos.current.y,
                deltaX: 0,
                deltaY: 0
            });
        document.addEventListener('touchend', onTouchEnd, true);
        document.addEventListener('touchmove', onTouchMove, true);
    }, [transform, onDragStart]);
    return {
        startMouseDrag,
        startTouchDrag
    };
}
