import { CanvasObjectRenderer } from '../renderers/CanvasObjectRenderer';
import { CanvasContextCamera } from './camera';
import { CanvasEvents } from './event';
import { CanvasContextNodes } from './nodes';
import { CanvasContextObjects } from './objects';
import { CanvasContextSettings } from './settings';
export class CanvasContext {
    constructor(setup, _settings = CanvasContextSettings.create()) {
        this._settings = _settings;
        this._events = new CanvasEvents();
        this._nodes = new CanvasContextNodes(this);
        this._objects = new CanvasContextObjects(this);
        this._camera = new CanvasContextCamera(this);
        this._viewportBounds = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            toJSON: function () {
                throw new Error('Function not implemented.');
            }
        };
        this.setRenderer(new CanvasObjectRenderer(this));
        // new SvgObjectRenderer(context)
        setup === null || setup === void 0 ? void 0 : setup.apply(this);
    }
    setRenderer(renderer) {
        this._canvasRenderer = renderer;
    }
    get settings() {
        return this._settings.settings;
    }
    get events() {
        return this._events;
    }
    get nodes() {
        return this._nodes;
    }
    get objects() {
        return this._objects;
    }
    get camera() {
        return this._camera;
    }
}
