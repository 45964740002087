import { jsx as _jsx } from "react/jsx-runtime";
import { Rectangle, Vector2, getRectanglePointNormal, LineSegment2D, ShapeLineType, ShapeLineCap, BazierCurve2D, RectanglePoint } from '@noodl/geometry';
import { useEffect, useRef, useState } from 'react';
import { ModifierType, ObjectStateEvent, ObjectState } from '../Canvas/context';
export function CanvasNodeConnection(props) {
    const objectIdRef = useRef(undefined);
    const [_, setTime] = useState(Date.now());
    // Create and delete
    const deleteContext = useRef(null);
    useEffect(() => {
        const context = props.context;
        if (context) {
            deleteContext.current = context;
            const curve = getBazierCurve(context, props);
            objectIdRef.current = context.objects.createOrUpdate(createShape(curve, objectIdRef.current, props));
            return function () {
                var _a;
                if (objectIdRef.current) {
                    (_a = deleteContext.current) === null || _a === void 0 ? void 0 : _a.objects.delete(objectIdRef.current);
                }
            };
        }
    }, [props.id]);
    // Listen to node moved events
    const eventsContext = useRef(null);
    useEffect(() => {
        const context = props.context;
        if (context) {
            eventsContext.current = context;
            const redraw = function (event) {
                if (event.nodeId === props.startId || event.nodeId === props.endId) {
                    setTime(Date.now());
                    if (event.modifier === ModifierType.Removed) {
                        props.onLostConnection && props.onLostConnection();
                    }
                }
            };
            const objectState = function (event) {
                if (event.shapeId === objectIdRef.current) {
                    const curve = getBazierCurve(props.context, props);
                    const point = curve.closestPointOffset(event.mousePosition);
                    props.outMousePosition && props.outMousePosition(point);
                    switch (event.state) {
                        case ObjectState.MouseEnter:
                            props.onMouseEnter && props.onMouseEnter();
                            break;
                        case ObjectState.MouseLeave:
                            props.onMouseLeave && props.onMouseLeave();
                            break;
                        case ObjectState.MouseMove:
                            props.onMouseMove && props.onMouseMove();
                            break;
                    }
                }
            };
            // @ts-ignore
            context.events.addEventListener('nodes', redraw);
            // @ts-ignore
            context.events.addEventListener(ObjectStateEvent.ID, objectState);
            return function () {
                if (eventsContext.current) {
                    // @ts-ignore
                    eventsContext.current.events.removeEventListener('nodes', redraw);
                    // @ts-ignore
                    eventsContext.current.events.removeEventListener(ObjectStateEvent.ID, objectState);
                }
            };
        }
    }, [props.startId, props.endId]);
    const curve = getBazierCurve(props.context, props);
    // Redraw
    const context = props.context;
    if (context && objectIdRef.current) {
        context.objects.createOrUpdate(createShape(curve, objectIdRef.current, props));
    }
    if (props.children) {
        let childPosition = curve.center;
        if (typeof props.childPosition === 'number' && props.childPosition !== 0.5) {
            childPosition = curve.atPoint(props.childPosition);
        }
        return (_jsx("div", Object.assign({ style: {
                position: 'absolute',
                // TODO: handle zoom
                transform: `translate(${childPosition.x}px, ${childPosition.y}px)`,
                width: 0,
                height: 0
            } }, { children: props.children })));
    }
    return null;
}
function getNodePosition(startPosition, targetPosition, lineType, horizontalAngles, node) {
    if (!node) {
        return {
            type: RectanglePoint.MiddleCenter,
            position: targetPosition,
            normal: startPosition.subtract(targetPosition).normalize()
        };
    }
    let type = node.bounds.closestIntermediatePointType(startPosition, horizontalAngles);
    let position = targetPosition;
    switch (lineType) {
        case 'floating': {
            const lineSegment = new LineSegment2D(startPosition, targetPosition);
            position = lineSegment.normal().multiplyByValue(30.0);
            break;
        }
        case 'fixed': {
            type = node.bounds.closestIntermediatePointType(startPosition, horizontalAngles);
            position = node.bounds.getPoint(type);
            break;
        }
        default: {
            position = node.bounds.getPoint(type);
            break;
        }
    }
    const normal = getRectanglePointNormal(type);
    return {
        type,
        position,
        normal
    };
}
function getBazierCurve(context, props) {
    const startNode = context.nodes.get(props.startId);
    const endNode = context.nodes.get(props.endId);
    let startPosition = Vector2.zero;
    if (props.startEnableAbsPosition) {
        startPosition = new Vector2(props.startX || 0, props.startY || 0);
    }
    else if ((startNode === null || startNode === void 0 ? void 0 : startNode.bounds) && startNode.bounds !== Rectangle.zero) {
        startPosition = startNode.bounds.center;
    }
    let endPosition = Vector2.zero;
    if (props.endEnableAbsPosition) {
        endPosition = new Vector2(props.endX || 0, props.endY || 0);
    }
    else if ((endNode === null || endNode === void 0 ? void 0 : endNode.bounds) && endNode.bounds !== Rectangle.zero) {
        endPosition = endNode.bounds.center;
    }
    const start = getNodePosition(endPosition, startPosition, props.anchorType, props.startNormalAngles, startNode);
    const end = getNodePosition(startPosition, endPosition, props.anchorType, props.endNormalAngles, endNode);
    const line = new LineSegment2D(start.position, end.position);
    const lineLength = line.length();
    const distanceCoefficient = typeof props.splineDistanceCoefficient !== 'undefined' ? props.splineDistanceCoefficient : 0.5;
    // https://www.jointjs.com/blog/how-to-create-nice-looking-curves-in-svg-with-fixed-tangents
    const k = lineLength * distanceCoefficient;
    return new BazierCurve2D(start.position, start.position.add(start.normal.multiplyByValue(k)), end.position.add(end.normal.multiplyByValue(k)), end.position);
}
function createShape(curve, id, props) {
    // TODO: Verify strokeDashArray better
    if (!Array.isArray(props.strokeDashArray)) {
        console.warn('strokeDashArray is not an array.', 'Value: ', props.strokeDashArray, ', Type: ', typeof props.strokeDashArray, ', Is Array: ', Array.isArray(props.strokeDashArray));
    }
    switch (props.anchorType) {
        default:
        case 'spline-segment': {
            return {
                kind: 'spline-segment',
                id,
                order: props.order,
                start: curve.start.add(new Vector2(props.startOffsetX || 0, props.startOffsetY || 0)),
                startControl: curve.startCtrl,
                startMargin: 0,
                startCap: props.startCap || ShapeLineCap.None,
                end: curve.end.add(new Vector2(props.endOffsetX || 0, props.endOffsetY || 0)),
                endControl: curve.endCtrl,
                endMargin: 0,
                endCap: props.endCap || ShapeLineCap.None,
                strokeColor: String(props.strokeColor),
                strokeWidth: props.strokeWidth || 1,
                strokeDashArray: props.strokeDashArray || [],
                strokeDashOffset: props.strokeDashOffset || 1.0
            };
        }
        case 'floating':
        case 'fixed': {
            return {
                kind: 'line',
                id,
                start: curve.start.add(new Vector2(props.startOffsetX || 0, props.startOffsetY || 0)),
                startCap: props.startCap || ShapeLineCap.None,
                end: curve.end.add(new Vector2(props.endOffsetX || 0, props.endOffsetY || 0)),
                endCap: props.endCap || ShapeLineCap.None,
                strokeColor: String(props.strokeColor),
                strokeWidth: props.strokeWidth || 0,
                strokeDashArray: props.strokeDashArray || [],
                strokeDashOffset: props.strokeDashOffset || 1.0,
                lineType: ShapeLineType.Default,
                lineCurve: 0
            };
        }
    }
}
