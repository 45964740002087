import { jsx as _jsx } from "react/jsx-runtime";
import { CanvasNodeConnection } from '@noodl/noodl-canvas/src/components/CanvasNodeConnection/CanvasNodeConnection';
export const canvasNodeConnection = {
    name: 'Canvas Node Connection',
    category: 'canvas.module',
    allowChildren: true,
    noodlNodeAsProp: true,
    getReactComponent() {
        return function (props) {
            function getContext() {
                var _a;
                const parent = props.noodlNode.getVisualParentNode();
                return (_a = parent === null || parent === void 0 ? void 0 : parent.model) === null || _a === void 0 ? void 0 : _a.context;
            }
            // Default is 45°, otherwise we convert it to radians.
            if (props.startNormalAngles === 45) {
                props.startNormalAngles = undefined;
            }
            else if (props.startNormalAngles) {
                props.startNormalAngles = props.startNormalAngles * (Math.PI / 180);
            }
            // Default is 45°, otherwise we convert it to radians.
            if (props.endNormalAngles === 45) {
                props.endNormalAngles = undefined;
            }
            else if (props.endNormalAngles) {
                props.endNormalAngles = props.endNormalAngles * (Math.PI / 180);
            }
            return _jsx(CanvasNodeConnection, Object.assign({ context: getContext() }, props));
        };
    },
    dynamicports: [
        {
            condition: 'anchorType = spline-segment',
            inputs: ['splineDistanceCoefficient']
        },
        {
            condition: 'startEnableAbsPosition = false',
            inputs: ['startId']
        },
        {
            condition: 'startEnableAbsPosition = true',
            inputs: ['startX', 'startY']
        },
        {
            condition: 'endEnableAbsPosition = false',
            inputs: ['endId']
        },
        {
            condition: 'endEnableAbsPosition = true',
            inputs: ['endX', 'endY']
        }
    ],
    inputProps: {
        id: {
            group: 'General',
            displayName: 'Id',
            type: 'string'
        },
        anchorType: {
            displayName: 'Type',
            group: 'General',
            default: 'spline-segment',
            type: {
                name: 'enum',
                enums: [
                    {
                        label: 'Floating',
                        value: 'floating'
                    },
                    {
                        label: 'Fixed To Anchor',
                        value: 'fixed'
                    },
                    {
                        label: 'Spline To Anchor',
                        value: 'spline-segment'
                    }
                ]
            }
        },
        childPosition: {
            displayName: 'Child Position',
            group: 'General',
            default: 0.5,
            type: 'number'
        },
        splineDistanceCoefficient: {
            displayName: 'Distance Coefficient',
            group: 'Spline',
            type: 'number',
            default: 0.5
        },
        startEnableAbsPosition: {
            displayName: 'Start Absolute Position',
            group: 'Start',
            default: false,
            type: 'boolean'
        },
        startId: {
            displayName: 'Start ID',
            group: 'Start',
            type: 'string'
        },
        startX: {
            displayName: 'Start X',
            group: 'Start',
            default: 0,
            type: 'number'
        },
        startY: {
            displayName: 'Start Y',
            group: 'Start',
            default: 0,
            type: 'number'
        },
        startOffsetX: {
            displayName: 'Start Offset X',
            group: 'Start',
            default: 0,
            type: 'number'
        },
        startOffsetY: {
            displayName: 'Start Offset Y',
            group: 'Start',
            default: 0,
            type: 'number'
        },
        startNormalAngles: {
            displayName: 'Start Horizontal Degree',
            group: 'Start',
            default: 45,
            type: 'number'
        },
        startCap: {
            displayName: 'Start Cap',
            group: 'Start',
            default: 'none',
            type: {
                name: 'enum',
                enums: [
                    {
                        label: 'None',
                        value: 'none'
                    },
                    {
                        label: 'Line',
                        value: 'line'
                    }
                ]
            }
        },
        endEnableAbsPosition: {
            displayName: 'End Absolute Position',
            group: 'End',
            default: false,
            type: 'boolean'
        },
        endId: {
            displayName: 'End ID',
            group: 'End',
            type: 'string'
        },
        endX: {
            displayName: 'End X',
            group: 'End',
            default: 0,
            type: 'number'
        },
        endY: {
            displayName: 'End Y',
            group: 'End',
            default: 0,
            type: 'number'
        },
        endOffsetX: {
            displayName: 'End Offset X',
            group: 'End',
            default: 0,
            type: 'number'
        },
        endOffsetY: {
            displayName: 'End Offset Y',
            group: 'End',
            default: 0,
            type: 'number'
        },
        endNormalAngles: {
            displayName: 'End Horizontal Degree',
            group: 'End',
            default: 45,
            type: 'number'
        },
        endCap: {
            displayName: 'End Cap',
            group: 'End',
            default: 'line',
            type: {
                name: 'enum',
                enums: [
                    {
                        label: 'None',
                        value: 'none'
                    },
                    {
                        label: 'Line',
                        value: 'line'
                    }
                ]
            }
        },
        order: {
            group: 'Style',
            displayName: 'Order',
            default: 0,
            type: 'number'
        },
        strokeColor: {
            displayName: 'Stroke Color',
            group: 'Style',
            default: 'blue',
            type: 'color'
        },
        strokeWidth: {
            displayName: 'Stroke Width',
            group: 'Style',
            default: 2,
            type: 'number'
        },
        strokeDashArray: {
            displayName: 'Stroke Dash Array',
            group: 'Style',
            default: [],
            type: 'array'
        },
        strokeDashOffset: {
            displayName: 'Stroke Dash Offset',
            group: 'Style',
            default: 0,
            type: 'number'
        }
    },
    outputProps: {
        outMousePosition: {
            displayName: 'Mouse Position',
            group: 'Data',
            type: 'number'
        },
        onMouseEnter: {
            displayName: 'Mouse Enter',
            group: 'Events',
            type: 'signal'
        },
        onMouseLeave: {
            displayName: 'Mouse Leave',
            group: 'Events',
            type: 'signal'
        },
        onMouseMove: {
            displayName: 'Mouse Move',
            group: 'Events',
            type: 'signal'
        },
        onLostConnection: {
            displayName: 'Lost Connection',
            group: 'Events',
            type: 'signal'
        }
    }
};
