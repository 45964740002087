import { Rectangle } from '@noodl/geometry';
import { ModifierType } from '../context';
export class CanvasContextNodes {
    constructor(context) {
        this.context = context;
        this._nodes = {};
        this._boundingBox = Rectangle.zero;
    }
    list() {
        return Object.values(this._nodes);
    }
    get(id) {
        return this._nodes[id] || null;
    }
    getBoundingBox() {
        return this._boundingBox;
    }
    create(id, meta) {
        var _a, _b;
        this._nodes[id] = {
            id,
            type: ((_b = (_a = meta.node) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.type) || '',
            node: meta.node,
            bounds: Rectangle.zero,
            methods: meta.methods
        };
        this.onChanged(id, ModifierType.Added);
    }
    delete(id) {
        delete this._nodes[id];
        this.onChanged(id, ModifierType.Removed);
    }
    updateMethods(id, methods) {
        if (this._nodes[id]) {
            this._nodes[id].methods = methods;
        }
    }
    update(nodes) {
        this._nodes = nodes;
        this.onChanged(null, ModifierType.Changed);
    }
    updateSingle(id, node) {
        this._nodes[id] = node;
        this.onChanged(id, ModifierType.Changed);
    }
    updateSingleTransform(id, boundingBox) {
        if (this._nodes[id]) {
            this._nodes[id].bounds = boundingBox;
            this.onChanged(id, ModifierType.Changed); // TODO: Debounce? Or just move to a spatial tree
            return true;
        }
        return false;
    }
    onChanged(id, modifier) {
        // Calculate objects bounding box
        const boundingBox = Rectangle.merge(Object.values(this._nodes).map((object) => object.bounds));
        if (boundingBox) {
            this._boundingBox = boundingBox;
        }
        else {
            this._boundingBox = Rectangle.zero;
        }
        // Notify that the objects have been changed
        this.context.events.dispatchNodesChanged(id, modifier);
    }
}
