import { CanvasBackgroundVariantEnumListWithTransparent, defaultDotsPattern, defaultRectanglePattern } from '@noodl/noodl-canvas/src/components/BackgroundPattern';
import { Canvas } from '@noodl/noodl-canvas/src/components/Canvas/Canvas';
import { CanvasContext } from '@noodl/noodl-canvas/src/components/Canvas/context';
export const canvasViewportNode = {
    name: 'Canvas Viewport',
    category: 'canvas.module',
    allowChildren: true,
    noodlNodeAsProp: true,
    getReactComponent() {
        return function (props) {
            props.context = props.noodlNode.model.context;
            return Canvas(props);
        };
    },
    methods: {
        setNodeModel(nodeModel) {
            // @ts-ignore
            this.__proto__.__proto__.setNodeModel.call(this, nodeModel);
            // @ts-expect-error
            nodeModel.context = new CanvasContext();
            // @ts-expect-error
            this._outputs['setContext'].getter = () => nodeModel.context;
        }
    },
    setup() { },
    dynamicports: [
        {
            condition: 'backgroundVariant = dots',
            inputs: ['backgroundDotsLowerDotSize', 'backgroundDotsUpperDotSize']
        },
        {
            condition: 'backgroundVariant = rectangles',
            inputs: ['backgroundRectanglethicknessX', 'backgroundRectanglethicknessY']
        }
    ],
    outputs: {
        setContext: {
            displayName: 'Context',
            group: 'Other',
            type: 'object'
        }
    },
    inputProps: {
        // cameraTranslationX: {
        //   displayName: "Position X",
        //   group: "Camera",
        //   default: 0,
        //   type: "number",
        // },
        // cameraTranslationY: {
        //   displayName: "Position Y",
        //   group: "Camera",
        //   default: 0,
        //   type: "number",
        // },
        cameraEnable: {
            displayName: 'Enable Input',
            group: 'Camera',
            default: true,
            type: 'boolean'
        },
        cameraEnableZoom: {
            displayName: 'Enable Zoom',
            group: 'Camera',
            default: false,
            type: 'boolean'
        },
        cameraScale: {
            displayName: 'Zoom',
            group: 'Camera',
            default: 1,
            type: 'number'
        },
        backgroundVariant: {
            displayName: 'Variant',
            group: 'Background',
            default: 'dots',
            type: {
                name: 'enum',
                enums: CanvasBackgroundVariantEnumListWithTransparent()
            }
        },
        backgroundDotsLowerDotSize: {
            displayName: 'Dots Lower Dot Size',
            group: 'Background',
            default: defaultDotsPattern.lowerDotSize,
            type: {
                name: 'number'
            }
        },
        backgroundDotsUpperDotSize: {
            displayName: 'Dots Upper Dot Size',
            group: 'Background',
            default: defaultDotsPattern.upperDotSize,
            type: {
                name: 'number'
            }
        },
        backgroundRectanglethicknessX: {
            displayName: 'Rectangle Thickness X',
            group: 'Background',
            default: defaultRectanglePattern.thicknessX,
            type: {
                name: 'number'
            }
        },
        backgroundRectanglethicknessY: {
            displayName: 'Rectangle Thickness Y',
            group: 'Background',
            default: defaultRectanglePattern.thicknessY,
            type: {
                name: 'number'
            }
        },
        backgroundSpacing: {
            displayName: 'Spacing',
            group: 'Background',
            default: 20,
            type: {
                name: 'number'
                // TODO: Add a limit
            }
        },
        backgroundColor: {
            displayName: 'Background Color',
            group: 'Background',
            default: '#FFFFFF',
            type: {
                name: 'color'
            }
        },
        backgroundForegroundColor: {
            displayName: 'Foreground Color',
            group: 'Background',
            default: '#F2F2F2',
            type: {
                name: 'color'
            }
        },
        backgroundOpacity: {
            displayName: 'Opacity',
            group: 'Background',
            default: 0.8,
            type: {
                name: 'number'
                // TODO: Add a limit
            }
        },
        debugShowNodesBoundingBox: {
            displayName: 'Show Nodes AABB',
            group: 'Debug',
            default: false,
            type: 'boolean'
        }
        // debugShowObjectsBoundingBox: {
        //   displayName: "Show Objects AABB",
        //   group: "Debug",
        //   default: false,
        //   type: "boolean",
        // },
    },
    outputProps: {
        mouseWorldPositionX: {
            displayName: 'Mouse Position X',
            group: 'Interaction',
            type: 'number'
        },
        mouseWorldPositionY: {
            displayName: 'Mouse Position Y',
            group: 'Interaction',
            type: 'number'
        },
        /**
         * Events
         */
        onCameraMoved: {
            displayName: 'On Camera Moved',
            group: 'Events',
            type: 'signal'
        },
        onNodesChanged: {
            displayName: 'On Nodes Changed',
            group: 'Events',
            type: 'signal'
        },
        onObjectsChanged: {
            displayName: 'On Objects Changed',
            group: 'Events',
            type: 'signal'
        }
    }
};
