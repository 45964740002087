import { jsx as _jsx } from "react/jsx-runtime";
import { CssTransformVar, Vector2 } from '@noodl/geometry';
import { useEffect, useRef } from 'react';
import { BackgroundPattern, BackgroundPatternVariant, CanvasBackgroundVariantEnumList, defaultCheckerboardPattern, defaultDotsPattern, defaultRectanglePattern } from '@noodl/noodl-canvas/src/components/BackgroundPattern';
export const canvasBackground = {
    name: 'Canvas Background',
    category: 'canvas.module',
    allowChildren: false,
    getReactComponent() {
        return function (props) {
            const cssVar = useRef(CssTransformVar.create());
            const rootRef = useRef(null);
            useEffect(() => {
                if (!rootRef.current)
                    return;
                cssVar.current.setPosition(rootRef.current, new Vector2(props.transformPositionX, props.transformPositionY));
                cssVar.current.setScale(rootRef.current, props.transformScale);
            }, [rootRef.current]);
            function getPattern() {
                switch (props.variant) {
                    default:
                    case BackgroundPatternVariant.Dots:
                        return {
                            kind: BackgroundPatternVariant.Dots,
                            lowerDotSize: props.dotsLowerDotSize,
                            upperDotSize: props.dotsUpperDotSize
                        };
                    case BackgroundPatternVariant.Rectangles:
                        return {
                            kind: BackgroundPatternVariant.Rectangles,
                            thicknessX: props.rectanglethicknessX,
                            thicknessY: props.rectanglethicknessY
                        };
                    case BackgroundPatternVariant.Checkerboard:
                        return defaultCheckerboardPattern;
                }
            }
            if (props.variant === BackgroundPatternVariant.Transparent) {
                return null;
            }
            return (_jsx(BackgroundPattern, { elementRef: rootRef, cssVar: cssVar.current, pattern: getPattern(), spacing: props.spacing, backgroundColor: props.backgroundColor, foregroundColor: props.foregroundColor, opacity: props.opacity }));
        };
    },
    dynamicports: [
        {
            condition: 'variant = dots',
            inputs: ['dotsLowerDotSize', 'dotsUpperDotSize']
        },
        {
            condition: 'variant = rectangles',
            inputs: ['rectanglethicknessX', 'rectanglethicknessY']
        }
    ],
    inputProps: {
        variant: {
            displayName: 'Variant',
            group: 'Style',
            default: 'dots',
            type: {
                name: 'enum',
                enums: CanvasBackgroundVariantEnumList()
            }
        },
        dotsLowerDotSize: {
            displayName: 'Dots Lower Dot Size',
            group: 'Style',
            default: defaultDotsPattern.lowerDotSize,
            type: {
                name: 'number'
            }
        },
        dotsUpperDotSize: {
            displayName: 'Dots Upper Dot Size',
            group: 'Style',
            default: defaultDotsPattern.upperDotSize,
            type: {
                name: 'number'
            }
        },
        rectanglethicknessX: {
            displayName: 'Rectangle Thickness X',
            group: 'Style',
            default: defaultRectanglePattern.thicknessX,
            type: {
                name: 'number'
            }
        },
        rectanglethicknessY: {
            displayName: 'Rectangle Thickness Y',
            group: 'Style',
            default: defaultRectanglePattern.thicknessY,
            type: {
                name: 'number'
            }
        },
        spacing: {
            displayName: 'Spacing',
            group: 'Style',
            default: 20,
            type: 'number'
        },
        backgroundColor: {
            displayName: 'Background',
            group: 'Style',
            default: '#FFFFFF',
            type: 'color'
        },
        foregroundColor: {
            displayName: 'Foreground',
            group: 'Style',
            default: '#F2F2F2',
            type: 'color'
        },
        opacity: {
            displayName: 'Opacity',
            group: 'Style',
            default: 1,
            type: 'number'
        },
        transformPositionX: {
            displayName: 'Pos X',
            group: 'Placement',
            default: 0,
            type: 'number'
        },
        transformPositionY: {
            displayName: 'Pos Y',
            group: 'Placement',
            default: 0,
            type: 'number'
        },
        transformScale: {
            displayName: 'Scale',
            group: 'Placement',
            default: 1,
            type: 'number'
        }
    }
};
